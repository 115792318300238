import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./EventsPage.scss"
import Loading from '../../components/loading/Loading';
import { getEvents, createEvent, deleteEvent, updateEvent } from '../../api/events';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, useMediaQuery, Button, Fab } from "@mui/material";
import { differenceInCalendarDays, getHours, getMinutes } from 'date-fns';
import dayjs from 'dayjs';
import DialogAllEventDay from '../../components/dialog/DialogAllEventDay/DialogAllEventDay';
import DialogEvent from '../../components/dialog/DialogEvent/DialogEvent';
import { Link } from 'react-router-dom';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowDown, Add } from '@mui/icons-material/';
import { useDispatch } from 'react-redux';
import { setInfo } from '../../store/snackbar';

export default function EventsPage() {
    const [value, setValue] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [openDetailEvent, setOpenDetailEvent] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [openAllDetailEvent, setOpenAllDetailEvent] = useState(false);
    const [selectedAllDetailEvent, setSelectedAllDetailEvent] = useState([]);
    const [openNewEvent, setOpenNewEvent] = useState(false);
    const { t } = useTranslation();
    const matches = useMediaQuery('(max-width:780px)');
    const dispatch = useDispatch();

    useEffect(() => {
        let didCancel = false;
        window.scrollTo(0, 0);

        async function retrieveEvents() {
            try {
                !didCancel && setEvents(await getEvents())
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "events.error.get" }))
                setIsLoading(true);
            }
        }

        Promise.all([retrieveEvents()])
        return () => { didCancel = true; };
    }, [])

    const isSameDay = (a, b) => {
        return differenceInCalendarDays(a, b) === 0;
    }

    const detailEvent = (event) => {
        setSelectedEvent(event);
        setOpenDetailEvent(true);
    }

    const seeAllEvents = (eventsOfDay) => {
        setSelectedAllDetailEvent(eventsOfDay);
        setOpenAllDetailEvent(true);
    }

    const onCreateEvent = async (event) => {
        setIsLoading(true);
        try {
            await createEvent(event);
            setEvents(await getEvents())
            setOpenNewEvent(false);
            setValue(new Date(event.start_at));
        } catch (e) {
            dispatch(setInfo({ open: true, message: "events.error.create" }))
        }
        setIsLoading(false);
    }

    const onUpdateEvent = async (event) => {
        setIsLoading(true);
        try {
            await updateEvent(event, event.id);
            if (selectedAllDetailEvent.length > 0) {
                let eventsOfDayTmp = [...selectedAllDetailEvent];
                let indexToUpdate = eventsOfDayTmp.findIndex((e) => e.id === event.id);
                if (indexToUpdate >= 0) {
                    eventsOfDayTmp[indexToUpdate] = event;
                    setSelectedAllDetailEvent(eventsOfDayTmp);
                }
            }
            setEvents(await getEvents())
            setOpenDetailEvent(false);
            setValue(new Date(event.start_at));
        } catch (e) {
            dispatch(setInfo({ open: true, message: "events.error.update" }))
        }
        setIsLoading(false);
    }


    const onDeleteEvent = async (eventId) => {
        setIsLoading(true);
        try {
            await deleteEvent(eventId);
            setEvents(await getEvents())
            if (selectedAllDetailEvent.length > 0) {
                let eventsOfDayTmp = [...selectedAllDetailEvent];
                let indexToDelete = eventsOfDayTmp.findIndex((e) => e.id === eventId);
                if (indexToDelete >= 0) {
                    eventsOfDayTmp.splice(indexToDelete, 1);
                    setSelectedAllDetailEvent(eventsOfDayTmp);
                }
            }
            setOpenDetailEvent(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "events.error.delete" }))
        }
        setIsLoading(false);
    }

    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            let eventsOfDay = events.filter(event => isSameDay(new Date(event.start_at), date))
            if (eventsOfDay.length > 0) {
                return <div className="eventsOfDay">
                    {
                        eventsOfDay.sort((a, b) => {
                            return new Date(a.start_at) - new Date(b.start_at)
                        }).slice(0, 3).map((e) => <p key={`event_${e.id}`} className="anEvent" onClick={() => detailEvent(e)}>
                            <span className="hours">
                                {getHours(new Date(e.start_at)) <= 9 ?
                                    `0${getHours(new Date(e.start_at))}`
                                    :
                                    getHours(new Date(e.start_at))}
                                h
                                {getMinutes(new Date(e.start_at)) <= 9 ?
                                    `0${getMinutes(new Date(e.start_at))}`
                                    :
                                    getMinutes(new Date(e.start_at))
                                }
                            </span>
                            {e.name}
                        </p>)
                    }
                    {
                        eventsOfDay.length > 3 && <Grid container alignItems="center" className="otherEvent" onClick={() => seeAllEvents(eventsOfDay)}>
                            <KeyboardDoubleArrowDown className="icon" />
                            {
                                !matches && <Trans i18nKey="events.other"
                                    values={{ count: eventsOfDay.length - 3 }}
                                    components={[]} />
                            }
                        </Grid>
                    }
                </div>
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    const onClickDay = (value, event) => {
        if (event && event.target && event.target.className) {
            if (!(event.target.className.includes("anEvent") || event.target.className.includes("otherEvent"))) {
                setSelectedEvent({
                    name: "",
                    start_at: dayjs(value),
                    end_at: dayjs(value).add(1, 'hours')
                })
                setOpenNewEvent(true);
            }
        }
    }

    const onClickCreate = () => {
        let now = new Date();
        setValue(now);
        setSelectedEvent({
            name: "",
            start_at: dayjs(now),
            end_at: dayjs(now).add(1, 'hours')
        })
        setOpenNewEvent(true);
    }

    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div className="eventPage">

            {matches && <Fab size="small" className="addButtonMobile"
                onClick={onClickCreate}>
                <Add className="addIconMobile" />
            </Fab>}
            <Grid container alignItems="center">
                <Grid item sm={3} xs={12}>
                    <Grid container justifyContent="flex-start" alignItems="center" className="goBack">
                        <KeyboardDoubleArrowLeft className="icon" />
                        <Link to="/">{t("actions.goBack")}</Link>
                    </Grid>
                </Grid>

                <Grid item sm={6} xs={12} className="title">
                    <h1>{t("events.title")}</h1>
                </Grid>

                {!matches && <Grid item xs={0} sm={3} style={{ textAlign: 'right' }}>
                    <Button
                        color="inherit"
                        variant="contained"
                        startIcon={<Add className="addIcon" />}
                        className="addButton"
                        onClick={onClickCreate}
                    >{t('events.add')}</Button>
                </Grid>
                }

            </Grid>


            {openAllDetailEvent && !!selectedAllDetailEvent && <DialogAllEventDay open={openAllDetailEvent} setOpen={setOpenAllDetailEvent} eventsOfDay={selectedAllDetailEvent} onDelete={onDeleteEvent} onUpdate={onUpdateEvent} />}
            {openNewEvent && <DialogEvent open={openNewEvent} setOpen={setOpenNewEvent} title={t('events.new.title')} onClick={onCreateEvent} event={selectedEvent} />}
            {openDetailEvent && !!selectedEvent && <DialogEvent open={openDetailEvent} setOpen={setOpenDetailEvent} title={t('events.edit.title')} onClick={onUpdateEvent} onDelete={onDeleteEvent} event={selectedEvent} editInfo={true} />}
            <Calendar onChange={setValue} value={value}
                tileContent={tileContent}
                onClickDay={onClickDay} />
        </div>
    );
}