import React, { useState, useEffect } from 'react';
import "./SettingsPage.scss"
import {
    Grid, Avatar, TextField, Button, useMediaQuery, FormControlLabel,
    FormGroup, Checkbox
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import { KeyboardDoubleArrowLeft } from '@mui/icons-material/';
import { Link } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import { getSenior, updateSenior } from '../../api/senior';
import { getSosContacts, createSosContact, updateSosContact, deleteSosContact } from '../../api/sos_contacts';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import AddCardSquare from '../../components/addCardSquare/AddCardSquare';
import AddCardRectangle from '../../components/addCardRectangle/AddCardRectangle';
import UserCardSquare from '../../components/userCardSquare/UserCardSquare';
import UserCardRectangle from '../../components/userCardRectangle/UserCardRectangle';
import _ from 'lodash'
import DialogAddSosContact from '../../components/dialog/DialogAddSosContact/DialogAddSosContact';
import { useDispatch } from 'react-redux';
import { setInfo } from '../../store/snackbar';

export default function SettingsPage() {
    const matches = useMediaQuery('(max-width:780px)');
    const dispatch = useDispatch();
    const dateNow = Date.now();
    const [isLoading, setIsLoading] = useState(true);
    const [senior, setSenior] = useState(null);
    const [startAt, setStartAt] = useState(dayjs(dateNow));
    const [endAt, setEndAt] = useState(dayjs(dateNow));
    const [isMuted, setIsMuted] = useState(false);

    const [users, setUsers] = useState([]);
    const [openAddContact, setOpenAddContact] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openUpdateContact, setOpenUpdateContact] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        let didCancel = false;

        window.scrollTo(0, 0);


        async function retrieveSenior() {
            try {
                setIsLoading(true);
                let seniorTmp = await getSenior();
                !didCancel && setSenior(seniorTmp)
                !didCancel && setIsMuted(seniorTmp.notifications_always_muted);
                let dateStartAt = new Date(dateNow);
                let startAtHours = parseInt(seniorTmp.notifications_mute_start_at.split(':')[0]);
                let startAtMinutes = parseInt(seniorTmp.notifications_mute_start_at.split(':')[1]);
                dateStartAt.setHours(startAtHours)
                dateStartAt.setMinutes(startAtMinutes)
                !didCancel && setStartAt(dayjs(dateStartAt))
                let dateStartEnd = new Date(dateNow);
                let endAtHours = parseInt(seniorTmp.notifications_mute_stop_at.split(':')[0]);
                let endAtMinutes = parseInt(seniorTmp.notifications_mute_stop_at.split(':')[1]);
                dateStartEnd.setHours(endAtHours)
                dateStartEnd.setMinutes(endAtMinutes)
                !didCancel && setEndAt(dayjs(dateStartEnd))

                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "settings.notifications.error.get" }))
                setIsLoading(true);
            }
        }

        async function retrieveSosContacts() {
            try {
                !didCancel && setUsers(await getSosContacts())
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "settings.sosContact.error.get" }))
                setIsLoading(true);
            }
        }


        Promise.all([retrieveSenior(), retrieveSosContacts()])
        return () => { didCancel = true; };
    }, [])

    const onCreateSosContact = async (firstname, lastname, email) => {
        setIsLoading(true);
        try {
            await createSosContact({ firstname, lastname, email });
            setUsers(await getSosContacts());
            setOpenAddContact(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "settings.sosContact.error.add" }))
        }
        setIsLoading(false);
    }

    const onUpdateSosContact = async (firstname, lastname, email) => {
        setIsLoading(true);
        try {
            await updateSosContact({ firstname, lastname, email }, selectedUser.id);
            setUsers(await getSosContacts());
            setSelectedUser(null);
            setOpenUpdateContact(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "settings.sosContact.error.update" }))
        }
        setIsLoading(false);
    }

    const onDeleteSosContact = async () => {
        setIsLoading(true);
        try {
            await deleteSosContact(selectedUser.id);
            setUsers(await getSosContacts());
            setSelectedUser(null);
            setOpenUpdateContact(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "settings.sosContact.error.delete" }))
        }
        setIsLoading(false);
    }


    const saveNotifications = async () => {
        setIsLoading(true);
        try {
            let startAtHours = dayjs(new Date(startAt)).format('HH');
            let startAtMinutes = dayjs(new Date(startAt)).format('mm');
            let newStartAt = `${startAtHours}:${startAtMinutes}`;
            let endAtHours = dayjs(new Date(endAt)).format('HH');
            let endAtMinutes = dayjs(new Date(endAt)).format('mm');
            let newEndAt = `${endAtHours}:${endAtMinutes}`;
            let newData = {
                notifications_always_muted: isMuted,
                notifications_mute_start_at: newStartAt,
                notifications_mute_stop_at: newEndAt
            }
            await updateSenior(newData);
            setSenior({
                ...senior,
                notifications_always_muted: isMuted,
                notifications_mute_start_at: newStartAt,
                notifications_mute_stop_at: newEndAt
            })
            setIsLoading(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "settings.notifications.error.update" }))
            setIsLoading(true);
        }
    }

    const handleClickContact = (contact) => {
        setSelectedUser(contact);
        setOpenUpdateContact(true);
    }

    const userComponent = (users) => {
        users.sort((a, b) => {
            if (a.firstname > b.firstname) return 1;
            if (a.firstname < b.firstname) return -1;
            return 0;
        })
        let cardUsers = users.map(el => <Grid item sm={2} xs={12} key={`user_${el.id}`} style={{ cursor: "pointer" }}  >
            {matches ? <UserCardRectangle user={el} edit={true} disabledAvatar={true} onClick={() => handleClickContact(el)} /> : <UserCardSquare edit={true} user={el} disabledAvatar={true} onClick={() => handleClickContact(el)} />}
        </Grid>)
        let chunkedArray = _.chunk([<Grid item sm={2} xs={12}>
            {matches ? <AddCardRectangle onClick={() => setOpenAddContact(true)} /> : <AddCardSquare onClick={() => setOpenAddContact(true)} />}
        </Grid>, ...cardUsers], 5);
        if (chunkedArray[chunkedArray.length - 1].length < 5) {
            chunkedArray[chunkedArray.length - 1] = _.concat(chunkedArray[chunkedArray.length - 1], Array(5 - chunkedArray[chunkedArray.length - 1].length).fill(<Grid item sm={2} xs={6}></Grid>))
        }
        return chunkedArray.map((subArr, i) => (
            <Grid container justifyContent="space-between" spacing={matches ? 1 : 2} style={{ marginBottom: "20px" }} key={`section_user_${i}`}>
                {
                    subArr.map((el, j) => <React.Fragment key={`user_card_${i}_${j}`}>{el}</React.Fragment>)
                }
            </Grid>)
        )
    }

    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div className="settingsPage">
            {openAddContact && <DialogAddSosContact open={openAddContact} setOpen={setOpenAddContact} title={t("settings.sosContact.add.title")} onClick={onCreateSosContact} />}
            {openUpdateContact && selectedUser && <DialogAddSosContact open={openUpdateContact} setOpen={setOpenUpdateContact} title={t("settings.sosContact.edit.title")} onClick={onUpdateSosContact} editInfo={selectedUser} onDelete={onDeleteSosContact} />}
            <Grid container alignItems="center">
                <Grid item sm={3} xs={12}>
                    <Grid container justifyContent="flex-start" alignItems="center" className="goBack">
                        <KeyboardDoubleArrowLeft className="icon" />
                        <Link to="/">{t("actions.goBack")}</Link>
                    </Grid>
                </Grid>

                <Grid item sm={6} xs={12} className="title">
                    <h1>{t("settings.title")}</h1>
                </Grid>
            </Grid>


            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                <Grid container alignItems="center">
                    <Grid item sm={5} xs={12} container direction="column" justifyContent="space-around" alignItems="center">
                        <Grid item>
                            <Avatar
                                className="avatarSenior"
                                sx={{ width: 200, height: 200 }}
                                alt={`${senior.firstname} ${senior.lastname}`}
                                src={senior.avatar} />
                        </Grid>
                        <Grid item>
                            <p className="name">{senior.firstname} {senior.lastname}</p>
                        </Grid>
                    </Grid>
                    <Grid item sm={7} xs={12} container alignItems="center" className="info" spacing={matches ? 0 : 2}>
                        <Grid item xs={12}><h2 className="subTitle">{t("settings.notifications.title")}</h2></Grid>
                        <Grid item xs={12}><p className="text">{t("settings.notifications.text")}</p></Grid>
                        <Grid item xs={12}><h3 className="subTitle">{t("settings.notifications.time")}</h3></Grid>
                        <Grid item sm={6} xs={12}>
                            <TimePicker
                                label={t('form.startAt.label')}
                                value={startAt}
                                onChange={setStartAt}
                                renderInput={(params) => <TextField {...params} fullWidth={true} className="input" />}
                            />

                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TimePicker
                                label={t('form.endAt.label')}
                                value={endAt}
                                onChange={setEndAt}
                                minTime={startAt}
                                renderInput={(params) => <TextField {...params} fullWidth={true} className="input" />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={isMuted} onChange={(e) => setIsMuted(e.target.checked)} />} label={t('settings.notifications.mute')} className="checkbox" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}
                            className="button">
                            <Button
                                variant="contained"
                                onClick={saveNotifications}
                            >
                                {t('actions.edit')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
            <h2 className="subTitle">{t("settings.sosContact.title")}</h2>
            {
                userComponent(users)
            }
        </div >
    );
}