import React, { useState/*, useEffect*/ } from 'react';
import "./DialogEvent.scss";
import {
    Dialog, DialogContent, DialogContentText, Button, TextField,
    useMediaQuery, Stack, Grid
} from '@mui/material/';
import DialogTitle from '../DialogTitle/DialogTitle';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DeleteOutline } from '@mui/icons-material/';
import DialogAreYouSure from '../DialogAreYouSure/DialogAreYouSure';
import { useDispatch } from 'react-redux';
import { setInfo } from '../../../store/snackbar';

export default function DialogEvent(props) {
    //const matches = useMediaQuery('(max-width:780px)');
    const { open, title, setOpen, editInfo, onClick, event, onDelete } = props;
    const [openAreYouSure, setOpenAreYouSure] = useState(false);
    const [name, setName] = useState(event.name);
    const [date, setDate] = useState(dayjs(event.start_at));
    const [startAt, setStartAt] = useState(dayjs(event.start_at));
    const [endAt, setEndAt] = useState(dayjs(event.end_at));
    const matches = useMediaQuery('(max-width:780px)');
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const handleEvent = async () => {
        let newEvent = {
            name, start_at: startAt, end_at: endAt
        }
        if (event.id) {
            newEvent.id = event.id
        }
        await onClick(newEvent);
    }

    const editStartAt = (v) => {
        setStartAt(dayjs(v));
        setEndAt(dayjs(v).add(1, 'hours'));
    }

    const editDate = (d) => {
        setDate(d);
        setStartAt(dayjs(d));
        setEndAt(dayjs(d).add(1, 'hours'));
    }

    const toDelete = async () => {
        try {
            setOpenAreYouSure(true);
            await onDelete(event.id)
        } catch (e) {
            dispatch(setInfo({ open: true, message: "events.error.delete" }))
        }
    }


    return (
        <>
            {openAreYouSure && editInfo && event.id && <DialogAreYouSure open={openAreYouSure} setOpen={() => setOpenAreYouSure(false)}
                title={t('events.delete.title')} content={t("actions.areYouSure")} onClick={toDelete} />}

            <Dialog open={open} onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth={'sm'}
                fullScreen={matches}
                className="dialogEvent"
            >
                <DialogTitle title={title} onClose={() => setOpen(false)} />
                <DialogContent>
                    <DialogContentText>

                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                            <Stack spacing={3}>
                                <TextField id="filled-basic" label={t('events.fields.name')} variant="filled"
                                    required={true}
                                    fullWidth={true}
                                    value={name}
                                    className="input"
                                    onChange={(e) => setName(e.target.value)} />

                                <DatePicker
                                    label={t('events.fields.date')}
                                    value={date}
                                    onChange={editDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <TimePicker
                                    label={t('form.startAt.label')}
                                    value={startAt}
                                    onChange={editStartAt}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <TimePicker
                                    label={t('form.endAt.label')}
                                    value={endAt}
                                    onChange={setEndAt}
                                    minTime={startAt}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                            </Stack>
                        </LocalizationProvider>
                        <div className="button">
                            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" disabled={_.isEmpty(name)} color="primary" onClick={handleEvent}>{editInfo ? t('actions.edit') : t('actions.create')}</Button>
                                </Grid>
                                <Grid item>
                                    {
                                        editInfo &&
                                        <Button variant="contained" color="primary" onClick={(e) => setOpenAreYouSure(true)}><DeleteOutline className="deleteIcon" /></Button>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog >
        </>
    );
}
