import React, { useState, useEffect } from 'react';
import { Paper, Grid, TextField, Button } from '@mui/material'
import { useTranslation } from 'react-i18next';
import "./ForgotPasswordPage.scss";
import { resetPassword } from '../../api/users';
import { NavLink } from 'react-router-dom';
import { REGEX_MAIL } from '../../config';
import { useDispatch } from 'react-redux';
import { setInfo } from '../../store/snackbar';

export default function ForgotPasswordPage(props) {
    const { t } = useTranslation();
    const [mail, setMail] = useState('');
    const [isCliked, setIsCliked] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const onKeyPressMail = (e) => {
        if (e.key === 'Enter' && REGEX_MAIL.test(mail.trim())) {
            //props.loginClick(mail)
        }
    }

    const handleResetPassword = async () => {
        try {
            await resetPassword(mail);
            setIsCliked(true);
        }
        catch (e) {
            dispatch(setInfo({ open: true, message: "forgotPwd.error.send" }))
        }

    }

    if (isCliked) {
        return (
            <Grid container justifyContent="center" alignItems="center" className="forgotPasswordPage">
                <Grid item xs={12} sm={8} >
                    <Paper className="paper">
                        <h1>{t('forgotPwd.title')}</h1>
                        <p>{t('forgotPwd.text')}</p>
                    </Paper>
                </Grid>
            </Grid>
        )

    }

    return (
        <Grid container justifyContent="center" alignItems="center" className="forgotPasswordPage">
            <Grid item xs={12} sm={8} >
                <Paper className="paper">
                    <h1>{t('forgotPwd.title')}</h1>
                    <TextField
                        id="mail"
                        label={t('form.mail.label')}
                        value={mail}
                        onChange={(event) => setMail(event.target.value)}
                        fullWidth
                        required
                        margin="normal"
                        className="input"
                        helperText={!!mail.trim() && !REGEX_MAIL.test(mail.trim()) ? t('form.mail.helper') : ''}
                        error={!!mail.trim() && !REGEX_MAIL.test(mail.trim())}
                        onKeyPress={onKeyPressMail}
                        variant="outlined"
                        autoFocus={true}
                    />

                    <div style={{ textAlign: "center" }}>
                        <Button disabled={
                            !mail
                            || (!!mail.trim() && !REGEX_MAIL.test(mail.trim()))
                        }
                            variant="contained"
                            onClick={handleResetPassword}
                        >
                            {t('forgotPwd.button')}
                        </Button>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <NavLink to="/login" className="link">
                            {t('login.button')}
                        </NavLink>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    )
}