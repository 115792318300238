import React from 'react';
import {
    Dialog, DialogContent, DialogContentText
} from '@mui/material/';
import DialogTitle from './DialogTitle/DialogTitle';
import Carousel from '../carousel/Carousel';

export default function DialogCarousel(props) {
    //const matches = useMediaQuery('(max-width:780px)');
    const { open, setOpen, photos, index } = props;
    const pictures = photos.map((el) =>  <img src={el.large_url} alt="" />)

    return (
        <Dialog open={open} onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth={'sm'}
            fullScreen={true}
        >
            <DialogTitle title="" onClose={() => setOpen(false)} />
            <DialogContent>
                <DialogContentText>
                    <Carousel carouselComponent={pictures} index={index}/>
                </DialogContentText>
            </DialogContent>
        </Dialog >
    );
}
