import React, { useState } from 'react';
import './Header.scss'
import { Link, useNavigate } from "react-router-dom";
import {
    Typography, Toolbar, Box, AppBar, Button, Avatar,
    useMediaQuery, Menu, MenuItem, Tooltip, IconButton, Grid
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../store/user';
import { useTranslation } from 'react-i18next';

import { /*LogoutOutlined,*/ AccountCircle } from '@mui/icons-material/';

export default function Header() {
    const { t } = useTranslation();
    const [anchorElUser, setAnchorElUser] = useState(null);
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:780px)');
    const loggedInUser = useSelector(state => state.user.loggedInUser);
    let navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout())
    }

    const goToProfile = () => {
        navigate('/profile');
    }

    return (
        <Box sx={{ flexGrow: 1 }} className="headerContainer">
            <AppBar position="static" className="appBar">
                <Toolbar className="toolbar">
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item sm={3} xs={0}>
                        </Grid>
                        <Grid item xs={6} style={{textAlign:matches ? "left" : "center"}}>
                            <Link to="/"><img src="/logoViktor.png" alt="Logo" className="logo" /></Link>
                        </Grid>
                        <Grid item xs={3} style={{textAlign:"right", paddingRight: "5px"}}>
                            {loggedInUser ?
                                <>
                                    <Tooltip title={t('navbar.userMenu.tooltip')}>
                                        <IconButton onClick={(e) => setAnchorElUser(e.currentTarget)} sx={{ p: 0 }} className="userButton">
                                            <Avatar
                                                className="avatar"
                                                sx={{ width: 24, height: 24 }}
                                                alt={`${loggedInUser.firstname} ${loggedInUser.lastName}`}
                                                src={loggedInUser.avatar} />
                                            {!matches && <span className="name">{loggedInUser.firstname}</span>}
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={() => setAnchorElUser(null)}
                                    >
                                        <MenuItem onClick={() => setAnchorElUser(null)}>
                                            <Typography textAlign="center" onClick={goToProfile}>{t('navbar.profile')}</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => setAnchorElUser(null)}>
                                            <Typography textAlign="center" onClick={handleLogout}>{t('navbar.logout')}</Typography>
                                        </MenuItem>
                                    </Menu>
                                </>
                                :
                                <Button sx={{ flexGrow: 0 }}
                                    color="inherit"
                                    component={Link}
                                    startIcon={<AccountCircle className="icon" />}
                                    to="/login"
                                    className="login"
                                >{!matches && t('navbar.login')}</Button>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
