import axios from 'axios';
import { API_URL } from '../config';

export const getEvents = async () => {
  try {
    const response = await axios.get(`${API_URL}/events`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data.events;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const createEvent = async (newEvent) => {
  try {
    const response = await axios.post(`${API_URL}/events`, newEvent, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const deleteEvent = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/events/${id}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const updateEvent = async (eventData, id) => {
  try {
    const response = await axios.put(`${API_URL}/events/${id}`, eventData, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}