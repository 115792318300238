import React, { useState } from 'react';
import "./DialogAddSosContact.scss";
import {
    Dialog, DialogContent, DialogContentText, Button, TextField,
    useMediaQuery, Grid
} from '@mui/material/';
import DialogTitle from '../DialogTitle/DialogTitle';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import * as localizedFormat from 'dayjs/plugin/localizedFormat'
import { DeleteOutline } from '@mui/icons-material/';
import DialogAreYouSure from '../DialogAreYouSure/DialogAreYouSure';
import { REGEX_MAIL } from '../../../config';
import { useDispatch } from 'react-redux';
import { setInfo } from '../../../store/snackbar';

export default function DialogAddSosContact(props) {
    //const matches = useMediaQuery('(max-width:780px)');
    const { open, setOpen, onClick, title, editInfo, onDelete } = props;
    const [openAreYouSure, setOpenAreYouSure] = useState(false);
    const [firstname, setFirstname] = useState(editInfo?.firstname || '');
    const [lastname, setLastname] = useState(editInfo?.lastname || '');
    const [email, setEmail] = useState(editInfo?.email || '');
    const matches = useMediaQuery('(max-width:780px)');
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    dayjs.locale(i18n.language);
    dayjs.extend(localizedFormat);


    const toDelete = async () => {
        try {
            setOpenAreYouSure(true);
            await onDelete()
        } catch (e) {
            dispatch(setInfo({ open: true, message: "settings.sosContact.error.delete" }))
        }
    }

    return (
        <>
            {openAreYouSure && editInfo && <DialogAreYouSure open={openAreYouSure} setOpen={() => setOpenAreYouSure(false)}
                title={t('settings.sosContact.delete.title')} content={t("actions.areYouSure")} onClick={toDelete} />}

            <Dialog open={open} onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth={'sm'}
                fullScreen={matches}
                className="dialogAddSosContactContainer"
            >
                <DialogTitle title={title} onClose={() => setOpen(false)} />
                <DialogContent>
                    <DialogContentText>
                        <Grid item sm={4} xs={12} className="formLabel">{t('form.firstname.label')} *</Grid>
                        <Grid item sm={8} xs={12}>
                            <TextField
                                id="firstname"
                                value={firstname}
                                onChange={(event) => setFirstname(event.target.value)}
                                fullWidth
                                required
                                margin="normal"
                                className="input"
                                /*helperText={!!firstname.trim() ? t('form.firstname.helper') : ''}
                                error={!!firstname.trim()}*/
                                /*onKeyPress={onKeyPressMail}*/
                                variant="outlined"
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12} className="formLabel">{t('form.lastname.label')} *</Grid>
                        <Grid item sm={8} xs={12}>
                            <TextField
                                id="lastname"
                                value={lastname}
                                onChange={(event) => setLastname(event.target.value)}
                                fullWidth
                                required
                                margin="normal"
                                className="input"
                                /*helperText={!!lastname.trim() ? t('form.lastname.helper') : ''}
                                error={!!lastname.trim()}*/
                                /*helperText={!!mail.trim() && !REGEX_MAIL.test(mail.trim()) ? t('form.mail.helper') : ''}
                                error={!!mail.trim() && !REGEX_MAIL.test(mail.trim())}
                                onKeyPress={onKeyPressMail}*/
                                variant="outlined"
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12} className="formLabel">{t('form.mail.label')} *</Grid>
                        <Grid item sm={8} xs={12}>
                            <TextField
                                id="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                fullWidth
                                required
                                margin="normal"
                                className="input"
                                variant="outlined"
                                helperText={!!email.trim() && !REGEX_MAIL.test(email.trim()) ? t('form.mail.helper') : ''}
                                error={!!email.trim() && !REGEX_MAIL.test(email.trim())}
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item className="button">
                                <Button variant="contained" disabled={_.isEmpty(firstname)
                                    || _.isEmpty(lastname)
                                    || _.isEmpty(email)
                                    || !REGEX_MAIL.test(email.trim())}
                                    color="primary"
                                    onClick={() => onClick(firstname, lastname, email)}>{editInfo ? t('actions.edit') : t('actions.create')}</Button>
                            </Grid>
                            <Grid item className="button">
                                {editInfo &&
                                    <Button variant="contained" color="primary" onClick={(e) => setOpenAreYouSure(true)}><DeleteOutline className="deleteIcon" /></Button>
                                }
                            </Grid>
                        </Grid>

                    </DialogContentText>
                </DialogContent>
            </Dialog >
        </>
    );
}
