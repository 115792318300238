export const fr = {
    home: {
        visio: `Appel vidéo`,
        messages: `Messages`,
        rdv: `Agenda`,
        medias: `Médias`,
        contacts: `Contacts`,
        settings: `Paramètres`
    },
    medias: {
        album: {
            count: `{{count}} élément(s)`,
            add: `Ajouter`,
            private: `privé`
        },
        list: {
            seeMore: `voir plus`,
            seeLess: `voir moins`,
            photos: `Albums photo`,
            musics: `Playlists`,
            movies: `Vidéothèques`
        },
        title: `médias`,
        addAlbum: {
            photos: `Ajouter un album photo`,
            musics: `Ajouter une playlist`,
            movies: `Ajouter une vidéothèque`,
            label: {
                name: `Nom de l'album`,
                private: `Rendre privé ?`
            },
            updatePhoto: `Modifier l'image de couverture`,
            addPhoto: `Ajouter une image de couverture`,
            deletePhoto: `Supprimer l'image de couverture`
        },
        editAlbum: {
            photos: `Modifier l'album photo`,
            musics: `Modifier la playlist`,
            movies: `Modifier la vidéothèque`,
        },
        deleteAlbum: {
            photos: `Supprimer l'album photo`,
            musics: `Supprimer l'album de musique`,
            movies: `Supprimer l'album de film`,
        },
        error: {
            getPhotoAlbums: `Une erreur est survenue à la récupération des albums photos`,
            addPhotoAlbum: `Une erreur est survenue à la création de l'album photo`,
            getMusicLists: `Une erreur est survenue à la récupération des playlists`,
            addMusicList: `Une erreur est survenue à la création de la playlist`,
            getMovieLists: `Une erreur est survenue à la récupération des vidéothèques`,
            addMovieList: `Une erreur est survenue à la création de la vidéothèque`,
            delete_photos: `Une erreur est survenue à la suppression de l'album photo`,
            delete_musics: `Une erreur est survenue à la suppression de la playlist`,
            delete_movies: `Une erreur est survenue à la suppression de la vidéothèque`

        }
    },
    photos: {
        title: `Photos`,
        dragAndDrop: `Glisser - déposer vos photos pour les ajouter à l’album`,
        delete: `Supprimer la photo`,
        error: {
            get: `Une erreur est survenue à la récupération des photos`,
            getAlbum: `Une erreur est survenue à la récupération de l'album photo`,
            update: `Une erreur est survenue à la sauvegarde des photos`,
            updateAlbum: `Une erreur est survenue à la mise à jour de l'album photo`,
            delete: `Une erreur est survenue à la suppression de la photo`
        }
    },
    musics: {
        title: `Musiques`,
        desc: `Cliquez sur une musique pour lancer l’écoute`,
        add: {
            title: `Ajouter une musique`,
            name: `Titre de la musique`,
            artist: `Nom de l'artiste`,
            url: `Lien de la musique`
        },
        delete: {
            title: `Supprimer la musique`
        },
        emptyList: `La playlist est vide, `,
        count: `{{count}} musique(s)`,
        error: {
            get: `Une erreur est survenue à la récupération des musiques`,
            getList: `Une erreur est survenue à la récupération de la playlist`,
            add: `Une erreur est survenue à l'ajout d'une musique`,
            updateList: `Une erreur est survenue à la mise à jour de playlist`,
            delete: `Une erreur est survenue à la suppression d'une musique`
        }
    },
    movies: {
        title: `Films`,
        desc: `Cliquez sur un film pour lancer le visionnage`,
        add: {
            title: `Ajouter un film`,
            name: `Titre du film`,
            url: `Lien du film`
        },
        delete: {
            title: `Supprimer le film`
        },
        emptyList: `La vidéothèque est vide, `,
        count: `{{count}} film(s)`,
        error: {
            get: `Une erreur est survenue à la récupération des films`,
            getList: `Une erreur est survenue à la récupération de la vidéothèque`,
            add: `Une erreur est survenue à l'ajout d'un film`,
            updateList: `Une erreur est survenue à la mise à jour de vidéothèque`,
            delete: `Une erreur est survenue à la suppression d'un film`
        }
    },
    events: {
        title: `Agenda`,
        other: `{{count}} autres(s)`,
        new: {
            title: `Créer un nouveau rendez-vous`
        },
        edit: {
            title: `Modifier un rendez-vous`
        },
        delete: {
            title: `Supprimer le rendez-vous`
        },
        all: {
            title: `Liste des rendez-vous`
        },
        fields: {
            name: `Nom du rendez-vous`,
            date: `Date`
        },
        add: `Créer`,
        error: {
            get: `Une erreur est survenue à la récupération des rendez-vous`,
            create: `Une erreur est survenue à l'ajout du rendez-vous`,
            update: `Une erreur est survenue à la mise à jour du rendez-vous`,
            delete: `Une erreur est survenue à la suppression du rendez-vous`
        }
    },
    users: {
        title: `Contacts`,
        new: {
            title: `Inviter un utilisateur`,
            invite: "Inviter"
        },
        me: `moi`,
        error: {
            get: `Les contacts ne sont pas accessibles pour le moment`,
            create: `Une erreur est survenue à l'invitation du contact`
        }
    },
    messages: {
        title: `Messages`,
        add: {
            title: `Nouveau message`,
            subject: `Sujet`,
            body: `Corps du message`
        },
        edit: {
            title: `Modifier le message`,
            updatedAt: `Envoyé le {{date}} à {{time}}`,
            readAt: `Lu le {{date}} à {{time}}`,
            notRead: `Non lu`
        },
        delete: {
            title: `Supprimer le message`
        },
        empty: `Aucun message n'a été envoyé à {{firstname}}, cliquez sur <0>"Nouveau message"</0> dès à présent !`,
        emptyDefault: `votre proche`,
        error: {
            get: `Une erreur est survenue à la récupération des messages`,
            send: `Une erreur est survenue à l'envoie du message`,
            update: `Une erreur est survenue à la mise à jour du message`,
            delete: `Une erreur est survenue à la suppression du message`
        }
    },
    visio: {
        title: `Appel vidéo`,
        copy: `Copier le lien d'invitation`,
        error: {
            get: `L'appel vidéo n'est pas disponible pour le moment`
        }
    },
    profile: {
        title: `Mon profil`,
        save: `Enregistrer`,
        password: `Mon mot de passe`,
        error: {
            update: `Une erreur est survenue à la mise à jour du profil`,
            updatePwd: `Une erreur est survenue à la mise à jour du mot de passe`
        }
    },
    invitation: {
        title: `Créer mon compte`,
        save: `Enregistrer`,
        error: {
            get: `Une erreur est survenue à la récupération du compte`,
            update: `Une erreur est survenue à la mise à jour du compte`
        }
    },
    login: {
        title: `Bienvenue sur le portail Viktor`,
        text: `Merci de vous identifier.`,
        button: `Se connecter`,
        forgotPwd: `Mot de passe oublié`,
        error: {
            login: `Une erreur est survenue à la connexion`
        }
    },
    forgotPwd: {
        title: `Mot de passe oublié`,
        button: `Réinitialiser`,
        text: `Si l'email est bien enregistré, vous recevrez dans quelques instants un lien de réinitialisation`,
        error: {
            send: `Une erreur est survenue à la demande de réinitialisation`
        }
    },
    resetPwd: {
        title: `Réinitialiser le mot de passe`,
        button: `Réinitialiser`,
        error: {
            get: `Une erreur est survenue à la récupération du compte invité`,
            update: `Une erreur est survenue à la mise à jour du mot de passe`
        }
    },
    settings: {
        title: `Paramètres`,
        notifications: {
            title: `Notifications`,
            text: `La box installée chez votre proche reçoit parfois des notifications. 
            Ça peut-être des notifications d'appels vidéo, des rappels de RDV, des alertes importantes du département, ou une confirmation de lancement du SOS. 
            Vous pouvez configurer la plage horaire sur laquelle votre proche peut recevoir ces notifications, afin de ne pas la déranger à des moments innoportuns.`,
            time: `Plage horaire active (pendant laquelle le proche va recevoir les notifications)`,
            mute: `Mode "ne pas déranger" (votre proche ne recevra jamais les notifications)`,
            error: {
                get: `Une erreur est survenue à la récupération des informations du patient`,
                update: `Une erreur est survenue à la mise à jour des notifications`,
            }
        },
        sosContact: {
            title: `Configuration SOS`,
            text: `La Box Viktor vous permet de faire une demande de contact par l'envoi d'un mail à vos proches.`,
            add: {
                title: `Nouveau contact SOS`
            },
            edit: {
                title: `Modifier le contact SOS`,
            },
            delete: {
                title: `Supprimer le contact SOS`
            },
            error: {
                get: `Une erreur est survenue à la récupération des contacts SOS`,
                add: `Une erreur est survenue à l'ajout du contact SOS`,
                update: `Une erreur est survenue à la mise à jour du contact SOS`,
                delete: `Une erreur est survenue à la suppression du contact SOS`
            }
        }
    },
    form: {
        phone: {
            label: `Téléphone`,
            helper: `Le numéro de téléphone doit contenir l'indicatif pays (+33601020304)`
        },
        mail: {
            label: `Email`,
            helper: `L'adresse mail est requise et doit être valide (exemple@exemple.com)`
        },
        password: {
            label: `Mot de passe`,
            helper: `Le mot de passe est requis et dois contenir 8 charactères dont: 1 nombre, 1 majuscule, 1 minuscule`
        },
        newPassword: {
            label: `Nouveau mot de passe`,
            helper: `Le nouveau mot de passe est requis et dois contenir 8 charactères dont: 1 nombre, 1 majuscule, 1 minuscule`
        },
        confirmedNewPassword: {
            label: `Confirmer nouveau mot de passe`,
            helper: `Les mots de passe ne correspondent pas`,
        },
        firstname: {
            label: `Prénom`,
            helper: `Le prénom est requis`
        },
        lastname: {
            label: `Nom`,
            helper: `Le nom est requis`
        },
        startAt: {
            label: `Heure de début`,
        },
        endAt: {
            label: `Heure de fin`
        }
    },
    actions: {
        create: `Créer`,
        edit: `Modifier`,
        yes: `Oui`,
        no: `Non`,
        areYouSure: `Êtes vous sûr ?`,
        goBack: `retour`,
        goBackMedias: `retour aux médias`,
    },
    navbar: {
        login: `Se connecter`,
        logout: `Se déconnecter`,
        profile: `Mon profil`,
        userMenu: {
            tooltip: `Mon compte`
        }
    },
    footer: {
        copyright: `Fabriqué avec <0/> par Fingertips`
    },
    error: {
        default: `Une erreur est survenue`
    }
}
