import React, { useState /*,useEffect*/ } from 'react';
import "./DialogAddUser.scss";
import {
    Dialog, DialogContent, DialogContentText, Button, TextField,
     useMediaQuery, Grid, Avatar, Fab
} from '@mui/material/';
import DialogTitle from '../DialogTitle/DialogTitle';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'
import { AddAPhoto } from '@mui/icons-material/';
import { REGEX_PHONE, REGEX_MAIL } from '../../../config';

export default function DialogAddUser(props) {
    //const matches = useMediaQuery('(max-width:780px)');
    const { open, setOpen, createUser, title } = props;
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [previewUrl, setPreviewUrl] = useState("");
    const matches = useMediaQuery('(max-width:780px)');
    const { t } = useTranslation();
    
    const handleChangePicture = async (event) => {
        const {
            target: {
                files: [file],
            }
        } = event;

        var reader = new FileReader();

        reader.onload = function (e) {
            setPreviewUrl(e.target.result);
        }

        reader.readAsDataURL(file); // convert to base64 string
    };



    const onClick = async () => {
        let newUser = {
            firstname,lastname, email, phone,
            avatar: previewUrl
        }

        await createUser(newUser);
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth={'sm'}
            fullScreen={matches}
            className="dialogAddUserContainer"
        >
            <DialogTitle title={title} onClose={() => setOpen(false)} />
            <DialogContent>
                <DialogContentText>
                    <Grid container alignItems="center">
                        <Grid item sm={5} xs={12} container direction="column" justifyContent="space-around" alignItems="center">
                            <Grid item>
                                <Avatar
                                    className="avatar"
                                    sx={{ width: 100, height: 100 }}
                                    alt={`${firstname} ${lastname}`}
                                    src={previewUrl} />

                            </Grid>
                            <Grid item>
                                <Fab size="small" className="upload" >
                                    <input accept="image/*" style={{ display: "none" }} id="icon-button-file" type="file" onChange={event => handleChangePicture(event)} />
                                    <label htmlFor="icon-button-file">
                                        <AddAPhoto />
                                    </label>
                                </Fab>
                            </Grid>
                        </Grid>
                        <Grid item sm={7} xs={12} container alignItems="center" className="info">
                            <Grid item sm={4} xs={12} className="formLabel">{t('form.firstname.label')} *</Grid>
                            <Grid item sm={8} xs={12}>
                                <TextField
                                    id="firstname"
                                    value={firstname}
                                    onChange={(event) => setFirstname(event.target.value)}
                                    fullWidth
                                    required
                                    margin="dense"
                                    className="input"
                                    /*helperText={!!firstname.trim() ? t('form.firstname.helper') : ''}
                                    error={!!firstname.trim()}*/
                                    /*onKeyPress={onKeyPressMail}*/
                                    variant="outlined"
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12} className="formLabel">{t('form.lastname.label')} *</Grid>
                            <Grid item sm={8} xs={12}>
                                <TextField
                                    id="lastname"
                                    value={lastname}
                                    onChange={(event) => setLastname(event.target.value)}
                                    fullWidth
                                    required
                                    margin="dense"
                                    className="input"
                                    /*helperText={!!lastname.trim() ? t('form.lastname.helper') : ''}
                                    error={!!lastname.trim()}*/
                                    /*helperText={!!mail.trim() && !REGEX_MAIL.test(mail.trim()) ? t('form.mail.helper') : ''}
                                    error={!!mail.trim() && !REGEX_MAIL.test(mail.trim())}
                                    onKeyPress={onKeyPressMail}*/
                                    variant="outlined"
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12} className="formLabel">{t('form.mail.label')} *</Grid>
                            <Grid item sm={8} xs={12}>
                                <TextField
                                    id="email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    fullWidth
                                    required
                                    margin="dense"
                                    className="input"
                                    variant="outlined"
                                    helperText={!!email.trim() && !REGEX_MAIL.test(email.trim()) ? t('form.mail.helper') : ''}
                                    error={!!email.trim() && !REGEX_MAIL.test(email.trim())}
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12} className="formLabel">{t('form.phone.label')}</Grid>
                            <Grid item sm={8} xs={12}>
                                <TextField
                                    id="phone"
                                    value={phone}
                                    onChange={(event) => setPhone(event.target.value)}
                                    fullWidth
                                    required
                                    margin="dense"
                                    className="input"
                                    helperText={!!phone.trim() && !REGEX_PHONE.test(phone.trim()) ? t('form.phone.helper') : ''}
                                    error={!!phone.trim() && !REGEX_PHONE.test(phone.trim())}
                                    /*onKeyPress={onKeyPressMail}*/
                                    variant="outlined"
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item xs={12}
                                className="button">
                                <Button
                                    variant="contained"
                                    onClick={onClick}
                                    disabled={
                                        _.isEmpty(firstname)
                                        || _.isEmpty(lastname)
                                        || _.isEmpty(email)  
                                        || !REGEX_MAIL.test(email.trim())
                                        || (!_.isEmpty(phone) && !REGEX_PHONE.test(phone.trim()))
                                    }
                                >
                                    {t('users.new.invite')}
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog >
    );
}
