import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import './DropZone.scss'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setInfo } from '../../store/snackbar';

export default function Dropzone(props) {
    const { onDrop } = props;
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    useEffect(() => {
        async function drop() {
            try {
                await onDrop(acceptedFiles);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "photos.error.update" }))
            }
        }
        if(acceptedFiles.length > 0) {
            drop();
        }
    }, [acceptedFiles])

    return (
        <section className="dropZoneContainer">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>{t('photos.dragAndDrop')}</p>
            </div>

        </section>
    );
}