import React, { useState, useEffect } from 'react';
import "./PhotosPage.scss"
import PictureSquare from '../../components/pictureSquare/PictureSquare';
import { Grid/*, useTheme*/, useMediaQuery, IconButton } from "@mui/material";
import _ from 'lodash'
import { useTranslation } from 'react-i18next';
import { getPhotos, getPhotoAlbums, createPhoto, deletePhoto, updatePhotoAlbum } from '../../api/photos'
import { useParams, Link } from 'react-router-dom';
import { KeyboardDoubleArrowLeft, Edit } from '@mui/icons-material/';
import Dropzone from '../../components/dropZone/DropZone';
import DialogAddAlbum from '../../components/dialog/DialogAddAlbum/DialogAddAlbum';
import DialogCarousel from '../../components/dialog/DialogCarousel';
import Loading from '../../components/loading/Loading';
import AddCardSquare from '../../components/addCardSquare/AddCardSquare';
import { useSelector, useDispatch } from 'react-redux';
import { setInfo } from '../../store/snackbar';

export default function PhotosPage() {
    const matches = useMediaQuery('(max-width:780px)');
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const loggedInUser = useSelector(state => state.user.loggedInUser);

    const limitPhotos = 20;
    //const theme = useTheme();
    const [photos, setPhotos] = useState([]);
    const [albumPhoto, setAlbumPhoto] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [openCarousel, setOpenCarousel] = useState(false);
    const [photoToOpen, setPhotoToOpen] = useState(0);
    const [areYouSure, setAreYouSure] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let didCancel = false;
        window.scrollTo(0, 0);
        async function retrieveAlbumsPhotos() {
            try {
                let albums = await getPhotoAlbums();
                let currentAlbum = albums.find((el) => el.id.toString() === id.toString());
                if (currentAlbum) {
                    !didCancel && setAlbumPhoto(currentAlbum);
                    setIsLoading(false);
                } else {
                    throw new Error("404")
                }
            } catch (e) {
                dispatch(setInfo({ open: true, message: "photos.error.getAlbum" }))
                setIsLoading(true);
            }
        }


        async function retrievePhotos() {
            try {
                !didCancel && setPhotos(await getPhotos({ id: id }))
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "photos.error.get" }))
                setIsLoading(true);
            }
        }

        Promise.all([retrievePhotos(), retrieveAlbumsPhotos()])

        return () => { didCancel = true; };
    }, [id])

    const toOpenCarousel = (i) => {
        if (!areYouSure) {
            setPhotoToOpen(i);
            setOpenCarousel(true);
        }
    }

    const toOpenAreYouSure = (val) => {
        setOpenCarousel(false);
        setAreYouSure(val);
    }

    const photoComponent = (album) => {
        return album.map((el, i) => <Grid item sm={2} xs={6} key={`photo_${i}`} container direction="column" onClick={() => toOpenCarousel(i)}>
            <PictureSquare media={el.thumb_url} ableDelete={albumPhoto?.user?.id.toString() === loggedInUser.id.toString()}
                onDelete={{ title: t("photos.delete"), content: t("actions.areYouSure"), onClick: () => onDeletePhoto(el.id), setOpen: toOpenAreYouSure, open: areYouSure }} />
        </Grid>
        );
    };

    const handleChangePicture = async (event) => {
        const {
            target: {
                files: [file],
            }
        } = event;

        var reader = new FileReader();

        reader.onload = async (e) => {
            let data = {
                image: e.target.result
            }
            try {
                await createPhoto(data, id);
                setPhotos(await getPhotos({ id: id }));
            } catch (e) {
                dispatch(setInfo({ open: true, message: "photos.error.update" }))
            }
        }

        reader.readAsDataURL(file); // convert to base64 string

    };

    const onDrop = async (acceptedFiles) => {

        setIsLoading(true);
        let countSend = 0;
        acceptedFiles.map(async (file, i) => {
            try {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async () => {
                    let data = {
                        image: reader.result
                    }
                    let isCreated = await createPhoto(data, id);
                    if (isCreated) {
                        countSend++;
                        if (countSend === acceptedFiles.length) {
                            setIsLoading(false);
                        }
                    }
                    setPhotos(await getPhotos({ id: id }));
                };
            } catch (e) {
                dispatch(setInfo({ open: true, message: "photos.error.update" }))
                setIsLoading(false);
            }
        });
    }

    const onDeletePhoto = async (idPhoto) => {
        setIsLoading(true);
        try {
            await deletePhoto(idPhoto, id);
            setPhotos(await getPhotos({ id: id }));
            setOpenCarousel(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "photos.error.delete" }))
        }
        setIsLoading(false);
    }

    const onUpdatePhotoAlbum = async (name, isPrivate) => {
        setIsLoading(true);
        try {
            let albumPhotoTmp = { name, is_private: isPrivate };
            await updatePhotoAlbum(albumPhotoTmp, albumPhoto.id);
            albumPhotoTmp.id = albumPhoto.id;
            albumPhotoTmp.user = albumPhoto.user;
            setAlbumPhoto(albumPhotoTmp)
            setOpenEdit(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "photos.error.updateAlbum" }))
        }
        setIsLoading(false);
    }

    const addPhoto = <Grid item sm={2} xs={6} container direction="column" className="addAlbum">
        <input accept="image/*" style={{ display: "none" }} id="icon-button-file" type="file" onChange={event => handleChangePicture(event)} />
        <label htmlFor="icon-button-file">
            <AddCardSquare />
        </label>
    </Grid>

    const fullAlbumDesktop = (album) => {
        let chunkedArray = _.chunk(album.length < limitPhotos && albumPhoto?.user?.id.toString() === loggedInUser.id.toString() ? [addPhoto, ...album] : album, 5);

        if (chunkedArray.length > 0 && chunkedArray[chunkedArray.length - 1].length < 5) {
            chunkedArray[chunkedArray.length - 1] = _.concat(chunkedArray[chunkedArray.length - 1], Array(5 - chunkedArray[chunkedArray.length - 1].length).fill(<Grid item sm={2} xs={6}></Grid>))
        }
        return chunkedArray.map((subArr, i) => (
            <Grid container justifyContent="space-between" spacing={matches ? 1 : 2} style={{ marginBottom: "20px" }} key={`section_photo_${i}`}>
                {
                    subArr.map(el => el)
                }
            </Grid>)
        )
    }

    const fullAlbumMobile = (album) => {
        let albumTmp = album.length < limitPhotos && albumPhoto?.user?.id.toString() === loggedInUser.id.toString() ? [addPhoto, ...album] : album;
        if (albumTmp % 2 !== 0) {
            albumTmp = [...albumTmp, <Grid item sm={2} xs={6}></Grid>]
        }

        return (
            <Grid container justifyContent="flex-start" spacing={matches ? 1 : 2} style={{ marginBottom: "20px" }}>
                {
                    albumTmp.map(el => el)
                }
            </Grid>)
    }

    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div className="photosPage">
            {openCarousel && <DialogCarousel
                open={openCarousel} setOpen={setOpenCarousel} photos={photos} index={photoToOpen}
            />
            }
            {openEdit &&
                <DialogAddAlbum
                    open={openEdit} setOpen={setOpenEdit} title={t('medias.editAlbum.photos')}
                    privateCheck={true} createAlbum={onUpdatePhotoAlbum}
                    editInfo={{ name: albumPhoto?.name, isPrivate: albumPhoto?.is_private }} />
            }
            <Grid container alignItems="center">
                <Grid item sm={3} xs={12}>
                    <Grid container justifyContent="flex-start" alignItems="center" className="goBack">
                        <KeyboardDoubleArrowLeft className="icon" />
                        <Link to="/medias">{t("actions.goBackMedias")}</Link>
                    </Grid>
                </Grid>
                <Grid item sm={6} xs={12} className="title" container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item>
                        <h2 >
                            {albumPhoto?.name}
                        </h2>
                    </Grid>
                    {
                        albumPhoto?.user?.id.toString() === loggedInUser.id.toString() &&
                        <Grid item>
                            <IconButton onClick={() => setOpenEdit(true)}>
                                <Edit className="icon" />
                            </IconButton>
                        </Grid>
                    }
                </Grid>

            </Grid>
            <h2 className="sectionTitle">{t("photos.title")}</h2>
            {
                <>
                    {
                        !matches ? fullAlbumDesktop(photoComponent(photos)) : fullAlbumMobile(photoComponent(photos))
                    }
                </>
            }
            {!matches && albumPhoto?.user?.id.toString() === loggedInUser.id.toString() && <div className={photos.length === 0 && "dropSectionEmpty"}><Dropzone onDrop={onDrop} /></div>}
        </div >
    );
}