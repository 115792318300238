import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { de } from './lang/de';
import { fr } from './lang/fr';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  de: {
    translation: de
  },
  fr: {
    translation: fr
  }
};

export const getLang = () => {
  let hostname = window.location.hostname;
  let domain = hostname.slice(hostname.lastIndexOf(".") + 1);
  let lang = "fr";
  switch (domain) {
    case "fr":
      lang = "fr";
      break;
    case "de":
      lang = "de";
      break;
    default:
      lang = "fr";
      break;
  }
  return lang;
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLang(),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;