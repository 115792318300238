import React, { useState } from 'react';
import "./DialogAddMessage.scss";
import {
    Dialog, DialogContent, DialogContentText, Button, TextField,
    useMediaQuery, Grid
} from '@mui/material/';
import DialogTitle from '../DialogTitle/DialogTitle';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'lodash'
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import * as localizedFormat from 'dayjs/plugin/localizedFormat'
import { DeleteOutline } from '@mui/icons-material/';
import DialogAreYouSure from '../DialogAreYouSure/DialogAreYouSure';
import { useDispatch } from 'react-redux';
import { setInfo } from '../../../store/snackbar';

export default function DialogAddMessage(props) {
    //const matches = useMediaQuery('(max-width:780px)');
    const { open, setOpen, onClick, title, editInfo, onDelete } = props;
    const [openAreYouSure, setOpenAreYouSure] = useState(false);
    const [subject, setSubject] = useState((!!editInfo && editInfo.subject) ? editInfo.subject : "");
    const [body, setBody] = useState((!!editInfo && editInfo.body) ? editInfo.body : "");
    const matches = useMediaQuery('(max-width:780px)');
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    dayjs.locale(i18n.language);
    dayjs.extend(localizedFormat);


    const toDelete = async () => {
        try {
            setOpenAreYouSure(true);
            await onDelete()
        } catch (e) {
            dispatch(setInfo({ open: true, message: "messages.error.delete" }))
        }
    }

    return (
        <>
            {openAreYouSure && editInfo && <DialogAreYouSure open={openAreYouSure} setOpen={() => setOpenAreYouSure(false)}
                title={t('messages.delete.title')} content={t("actions.areYouSure")} onClick={toDelete} />}

            <Dialog open={open} onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth={'sm'}
                fullScreen={matches}
                className="dialogAddMessageContainer"
            >
                <DialogTitle title={title} onClose={() => setOpen(false)} />
                <DialogContent>
                    <DialogContentText>
                        <TextField label={t('messages.add.subject')} variant="filled"
                            required={true}
                            fullWidth={true}
                            value={subject}
                            className="input"
                            onChange={(e) => setSubject(e.target.value)} />
                        <TextField label={t('messages.add.body')} variant="filled"
                            required={true}
                            fullWidth={true}
                            value={body}
                            className="input"
                            multiline
                            rows={4}
                            onChange={(e) => setBody(e.target.value)} />
                        {editInfo &&
                            <Grid container justifyContent="space-between" spacing={2}>
                                <Grid item>
                                    <p className="dateInfo">
                                        <Trans i18nKey="messages.edit.updatedAt"
                                            values={{
                                                date: dayjs(editInfo.updated_at).format('l').toString(),
                                                time: dayjs(editInfo.updated_at).format('LT').toString()
                                            }}
                                            components={[]} />
                                    </p>
                                </Grid>

                                <Grid item>
                                    <p className="dateInfo">
                                        {
                                            editInfo.is_read ?
                                                <Trans i18nKey="messages.edit.readAt"
                                                    values={{
                                                        date: dayjs(editInfo.is_read).format('l').toString(),
                                                        time: dayjs(editInfo.is_read).format('LT').toString()
                                                    }}
                                                    components={[]} />
                                                :
                                                t("messages.edit.notRead")
                                        }
                                    </p>
                                </Grid>
                            </Grid>
                        }
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item className="button">
                                <Button variant="contained" disabled={_.isEmpty(subject) || _.isEmpty(body)} color="primary" onClick={() => onClick(subject, body)}>{editInfo ? t('actions.edit') : t('actions.create')}</Button>
                            </Grid>
                            <Grid item className="button">
                                {editInfo &&
                                    <Button variant="contained" color="primary" onClick={(e) => setOpenAreYouSure(true)}><DeleteOutline className="deleteIcon" /></Button>
                                }
                            </Grid>
                        </Grid>

                    </DialogContentText>
                </DialogContent>
            </Dialog >
        </>
    );
}
