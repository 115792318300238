import React, { useState, useEffect } from 'react';
import "./MoviesPage.scss"
//import PictureSquare from '../../components/pictureSquare/PictureSquare';
import { Grid/*, useTheme*/, useMediaQuery, IconButton, Button } from "@mui/material";
//import _ from 'lodash'
import { useTranslation, Trans } from 'react-i18next';
import { getMovies, getAlbumsMovies, updateMovieList, createMovie, deleteMovie } from '../../api/movies'
import { useParams, Link } from 'react-router-dom';
import { KeyboardDoubleArrowLeft, Edit, PlayCircle, Delete, Add } from '@mui/icons-material/';
import Loading from '../../components/loading/Loading';
import { useSelector, useDispatch } from 'react-redux';
import DialogAddAlbum from '../../components/dialog/DialogAddAlbum/DialogAddAlbum';
import DialogAreYouSure from '../../components/dialog/DialogAreYouSure/DialogAreYouSure';
import DialogAddMovie from '../../components/dialog/DialogAddMovie/DialogAddMovie';
import { setInfo } from '../../store/snackbar';

export default function VideosPage() {
    const matches = useMediaQuery('(max-width:780px)');
    const { t } = useTranslation();
    const { id } = useParams();
    //const theme = useTheme();
    const [movies, setMovies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [movieList, setMovieList] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAddMovie, setOpenAddMovie] = useState(false);
    const [openAreYouSure, setOpenAreYouSure] = useState(false);
    const [selectedMovie, setSelectedMovie] = useState(null);
    const [hoverOn, setHoverOn] = useState(-1);
    const loggedInUser = useSelector(state => state.user.loggedInUser);
    const dispatch = useDispatch()

    useEffect(() => {
        let didCancel = false;
        window.scrollTo(0, 0);
        async function retrieveMoviesLists() {
            try {
                let movieLists = await getAlbumsMovies();
                let currentList = movieLists.find((el) => el.id.toString() === id.toString());
                if (currentList) {
                    !didCancel && setMovieList(currentList);
                    setIsLoading(false);
                } else {
                    throw new Error("404")
                }
            } catch (e) {
                dispatch(setInfo({ open: true, message: "movies.error.getList" }))
                setIsLoading(true);
            }
        }

        async function retrieveMovies() {
            try {
                !didCancel && setMovies(await getMovies({ id: id }))
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "movies.error.get" }))
                setIsLoading(true);
            }
        }

        if (id) {
            Promise.all([retrieveMovies(), retrieveMoviesLists()])
        }

        return () => { didCancel = true; };
    }, [id])

    const onDelete = (el) => {
        setSelectedMovie(el);
        setOpenAreYouSure(true);
    }


    const movieComp = (el, i) => <>
        <Grid container alignItems="center" justifyContent="flex-start"
            className="movieContainer" >
            <Grid item xs={1} className="numberContainer" onMouseOver={() => setHoverOn(i)} onMouseOut={() => setHoverOn(-1)}
                onClick={() => window.open(el.url, '_blank')}>
                {
                    hoverOn === i ?
                        <PlayCircle fontSize="large" />
                        :
                        <span className="number">{i}</span>
                }
            </Grid>
            <Grid item xs={8} sm={10} onMouseOver={() => setHoverOn(i)} onMouseOut={() => setHoverOn(-1)}
                onClick={() => window.open(el.url, '_blank')}>
                <p className="title">{el.name}</p>
            </Grid>
            <Grid item sm={1} xs={2}>
                {
                    movieList?.user?.id.toString() === loggedInUser.id.toString() &&
                    <Delete fontSize="large" onClick={() => onDelete(el)} />
                }</Grid>
        </Grid>
    </>


    const onUpdateMovieList = async (name, isPrivate, media) => {
        setIsLoading(true);
        try {
            let movieListTmp = { name };
            if (media !== movieList.media) {
                movieListTmp.media = media;
            }
            await updateMovieList(movieListTmp, movieList.id);
            movieListTmp.id = movieList.id;
            movieListTmp.media = media;
            movieListTmp.user = movieList.user;
            setMovieList(movieListTmp)
            setOpenEdit(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "movies.error.updateList" }))
        }
        setIsLoading(false);
    }


    const onAddMovie = async (name, url) => {
        setIsLoading(true);
        try {
            let movieTmp = { name, url };
            let newMovie = await createMovie(movieTmp, movieList.id);
            movieTmp.id = newMovie.id;
            setMovies([...movies, movieTmp])
            setOpenAddMovie(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "movies.error.add" }))
        }
        setIsLoading(false);
    }

    const toDelete = async () => {
        setIsLoading(true);
        try {
            await deleteMovie(movieList.id, selectedMovie.id);
            let moviesTmp = [...movies];
            let indexToDelete = moviesTmp.findIndex((el) => el.id === selectedMovie.id);
            if (indexToDelete >= 0) {
                moviesTmp.splice(indexToDelete, 1);
                setMovies(moviesTmp)
            }
            setOpenAreYouSure(false);
            setSelectedMovie(null);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "movies.error.delete" }))
        }
        setIsLoading(false);
    }


    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div className="moviesPage">
            {openAreYouSure && selectedMovie && selectedMovie.id && <DialogAreYouSure open={openAreYouSure} setOpen={() => setOpenAreYouSure(false)}
                title={t('movies.delete.title')} content={t("actions.areYouSure")} onClick={toDelete} />}

            {openEdit && <DialogAddAlbum
                open={openEdit} setOpen={setOpenEdit} title={t('medias.editAlbum.movies')}
                privateCheck={false} createAlbum={onUpdateMovieList}
                enabledPhoto={true}
                editInfo={{ name: movieList?.name, isPrivate: false, previewUrl: movieList?.media }} />
            }
            {openAddMovie && <DialogAddMovie
                open={openAddMovie} setOpen={setOpenAddMovie} title={t('movies.add.title')}
                onClick={onAddMovie} />
            }
            <Grid container alignItems="center">
                <Grid item sm={3} xs={12}>
                    <p>
                        <Grid container justifyContent="flex-start" alignItems="center" className="goBack">
                            <KeyboardDoubleArrowLeft className="icon" />
                            <Link to="/medias">{t("actions.goBackMedias")}</Link>
                        </Grid>
                    </p>
                </Grid>
                <Grid item sm={6} xs={12} className="title" container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item>
                        <h2 >
                            {movieList?.name}
                        </h2>
                    </Grid>
                    {
                        movieList?.user?.id.toString() === loggedInUser.id.toString() &&
                        <Grid item>
                            <IconButton onClick={() => setOpenEdit(true)}>
                                <Edit className="icon" />
                            </IconButton>
                        </Grid>
                    }
                </Grid>

            </Grid>
            <h2 className="sectionTitle">{t("movies.title")}</h2>
            <p className="text">{t('movies.desc')}</p>
            <Grid container justifyContent="space-between" alignItems={matches ? "center" : "flex-start"} direction={matches ? "column-reverse" : "row"}>
                <Grid item xs={12} sm={5}>
                    {
                        movieList?.user?.id.toString() === loggedInUser.id.toString() &&
                        <Button
                            color="inherit"
                            variant="contained"
                            startIcon={<Add className="addIcon" />}
                            className="addButton"
                            fullWidth={matches}
                            onClick={() => setOpenAddMovie(true)}
                        >{t('movies.add.title')}</Button>
                    }
                    {
                        movies.map((el, i) => <>{movieComp(el, i + 1)}</>)
                    }
                </Grid>

                <Grid item xs={12} sm={7} container direction="column" alignItems="center">
                    <img src={movieList?.media} className="img" alt="movie cover" />
                    <p><Trans i18nKey="movies.count"
                        values={{ count: movies.length }}
                        components={[]} /></p>


                </Grid>
            </Grid>
        </div>
    );
}