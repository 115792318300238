import React, { useState, useEffect } from 'react';
import "./UsersPage.scss"
import { Grid/*, useTheme*/, useMediaQuery } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { getUsers, createUser } from '../../api/users'
import { KeyboardDoubleArrowLeft } from '@mui/icons-material/';
import Loading from '../../components/loading/Loading';
import { Link } from 'react-router-dom';
import AddCardSquare from '../../components/addCardSquare/AddCardSquare';
import AddCardRectangle from '../../components/addCardRectangle/AddCardRectangle';
import UserCardSquare from '../../components/userCardSquare/UserCardSquare';
import UserCardRectangle from '../../components/userCardRectangle/UserCardRectangle';
import DialogAddUser from '../../components/dialog/DialogAddUser/DialogAddUser';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setInfo } from '../../store/snackbar';
export default function UsersPage() {
    let navigate = useNavigate();
    const matches = useMediaQuery('(max-width:780px)');
    const { t } = useTranslation();
    const loggedInUser = useSelector(state => state.user.loggedInUser);

    //const theme = useTheme();
    const [users, setUsers] = useState([]);
    const [openAddUser, setOpenAddUser] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        let didCancel = false;

        window.scrollTo(0, 0);
        async function retrieveUsers() {
            try {
                !didCancel && setUsers(await getUsers());
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "users.error.get" }))
                setIsLoading(false);
            }
        }

        Promise.all([retrieveUsers()])

        return () => { didCancel = true; };
    }, [])

    const goToProfile = () => {
        navigate('/profile');
    }

    if (isLoading) {
        return (<Loading />)
    }

    const userComponent = (users) => {
        users.sort((a, b) => {
            if (a.firstname > b.firstname) return 1;
            if (a.firstname < b.firstname) return -1;
            return 0;
        })
        let cardUsers = users.map(el => <Grid item sm={2} xs={12} key={`user_${el.id}`} >
            {matches ? <UserCardRectangle user={el} edit={el.id === loggedInUser.id} onClick={goToProfile} /> : <UserCardSquare user={el} edit={el.id === loggedInUser.id} onClick={goToProfile} />}
        </Grid>)
        let chunkedArray = _.chunk([<Grid item sm={2} xs={12}>
            {matches ? <AddCardRectangle onClick={() => { setOpenAddUser(true) }} /> : <AddCardSquare onClick={() => { setOpenAddUser(true) }} />}
        </Grid>, ...cardUsers], 5);
        if (chunkedArray[chunkedArray.length - 1].length < 5) {
            chunkedArray[chunkedArray.length - 1] = _.concat(chunkedArray[chunkedArray.length - 1], Array(5 - chunkedArray[chunkedArray.length - 1].length).fill(<Grid item sm={2} xs={6}></Grid>))
        }
        return chunkedArray.map((subArr, i) => (
            <Grid container justifyContent="space-between" spacing={matches ? 1 : 2} style={{ marginBottom: "20px" }} key={`section_user_${i}`}>
                {
                    subArr.map((el, j) => <React.Fragment key={`user_card_${i}_${j}`}>{el}</React.Fragment>)
                }
            </Grid>)
        )
    }

    const onCreateUser = async (user) => {
        setIsLoading(true);
        try {
            await createUser(user);
            setUsers(await getUsers());
            setOpenAddUser(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "users.error.create" }))
        }
        setIsLoading(false);
    }

    return (
        <div className="userPage">
            <DialogAddUser open={openAddUser} setOpen={setOpenAddUser} createUser={onCreateUser} title={t('users.new.title')} privateCheck={true} />
            <Grid container alignItems="center">
                <Grid item sm={3} xs={12}>
                    <Grid container justifyContent="flex-start" alignItems="center" className="goBack">
                        <KeyboardDoubleArrowLeft className="icon" />
                        <Link to="/">{t("actions.goBack")}</Link>
                    </Grid>
                </Grid>

                <Grid item sm={6} xs={12} className="title">
                    <h1>{t("users.title")}</h1>
                </Grid>




            </Grid>
            {
                userComponent(users)
            }
        </div >
    );
}