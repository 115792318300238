export const de = {
    home: {
        visio: `Videoanruf`,
        messages: `Benachrichtigungen`,
        rdv: `Kalender`,
        medias: `Medien`,
        contacts: `Kontakte`,
        settings: `Einstellungen`
    },
    medias: {
        album: {
            count: `{{count}} Element(e)`,
            add: `Hinzufügen`,
            private: `privat`
        },
        list: {
            seeMore: `Mehr anzeigen`,
            seeLess: `Weniger anzeigen`,
            photos: `Fotoalben`,
            musics: `Musikalben`,
            movies: `Videotheken`
        },
        title: `Medien`,
        addAlbum: {
            photos: `Fotoalbum hinzufügen`,
            musics: `Musikalbum hinzufügen`,
            movies: `Videothek hinzufügen`,
            label: {
                name: `Name eingeben`,
                private: `Privat stellen?`
            },
            updatePhoto: `Titelbild bearbeiten`,
            addPhoto: `Titelbild hinzufügen`,
            deletePhoto: `Titelbild entfernen`
        },
        editAlbum: {
            photos: `Fotoalbum bearbeiten`,
            musics: `Musikalbum bearbeiten`,
            movies: `Videothek bearbeiten`,
        },
        deleteAlbum: {
            photos: `Fotoalbum löschen`,
            musics: `Musikalbum löschen`,
            movies: `Videothek löschen`,
        },
        error: {
            getPhotoAlbums: `Une erreur est survenue à la récupération des albums photos`,//TO-TRANSLATE
            addPhotoAlbum: `Une erreur est survenue à la création de l'album photo`,//TO-TRANSLATE
            getMusicLists: `Une erreur est survenue à la récupération des playlists`,//TO-TRANSLATE
            addMusicList: `Une erreur est survenue à la création de la playlist`,//TO-TRANSLATE
            getMovieLists: `Une erreur est survenue à la récupération des vidéothèques`,//TO-TRANSLATE
            addMovieList: `Une erreur est survenue à la création de la vidéothèque`,//TO-TRANSLATE
            delete_photos: `Une erreur est survenue à la suppression de l'album photo`,//TO-TRANSLATE
            delete_musics: `Une erreur est survenue à la suppression de la playlist`,//TO-TRANSLATE
            delete_movies: `Une erreur est survenue à la suppression de la vidéothèque`//TO-TRANSLATE

        }
    },
    photos: {
        title: `Fotos`,
        dragAndDrop: `Nutzen Sie Drag & Drop, um Ihre Fotos dem Album hinzuzufügen`,
        delete: `Foto löschen`,
        error: {
            get: `Une erreur est survenue à la récupération des photos`,//TO-TRANSLATE
            getAlbum: `Une erreur est survenue à la récupération de l'album photo`,//TO-TRANSLATE
            update: `Une erreur est survenue à la sauvegarde des photos`,//TO-TRANSLATE
            updateAlbum: `Une erreur est survenue à la mise à jour de l'album photo`,//TO-TRANSLATE
            delete: `Une erreur est survenue à la suppression de la photo`//TO-TRANSLATE
        }
    },
    musics: {
        title: `Musik`,
        desc: `Klicken Sie auf ein Musikstück, um es abzuspielen`,
        add: {
            title: `Musikstück hinzufügen`,
            name: `Name des Musikstücks`,
            artist: `Name des Künstlers`,
            url: `Link zum Musikstück`
        },
        delete: {
            title: `Supprimer la musique`
        },
        emptyList: `Das Musikalbum ist leer, `,
        count: `{{count}} Musikstück(e)`,
        error: {
            get: `Une erreur est survenue à la récupération des musiques`,//TO-TRANSLATE
            getList: `Une erreur est survenue à la récupération de la playlist`,//TO-TRANSLATE
            add: `Une erreur est survenue à l'ajout d'une musique`,//TO-TRANSLATE
            updateList: `Une erreur est survenue à la mise à jour de playlist`,//TO-TRANSLATE
            delete: `Une erreur est survenue à la suppression d'une musique`//TO-TRANSLATE
        }
    },
    movies: {
        title: `Filme`,
        desc: `Klicken Sie auf einen Film, um ihn anzusehen`,
        add: {
            title: `Film hinzufügen`,
            name: `Name des Films`,
            url: `Link zum Film`
        },
        delete: {
            title: `Film löschen`
        },
        emptyList: `Die Videothek ist leer, `,
        count: `{{count}} Film(e)`,
        error: {
            get: `Une erreur est survenue à la récupération des films`,//TO-TRANSLATE
            getList: `Une erreur est survenue à la récupération de la vidéothèque`,//TO-TRANSLATE
            add: `Une erreur est survenue à l'ajout d'un film`,//TO-TRANSLATE
            updateList: `Une erreur est survenue à la mise à jour de vidéothèque`,//TO-TRANSLATE
            delete: `Une erreur est survenue à la suppression d'un film`//TO-TRANSLATE
        }
    },
    events: {
        title: `Kalender`,
        other: `{{count}} andere(s)`,
        new: {
            title: `Neuen Termin erstellen`
        },
        edit: {
            title: `Termin bearbeiten`
        },
        delete: {
            title: `Termin löschen`
        },
        all: {
            title: `Terminliste`
        },
        fields: {
            name: `Name des Termins`,
            date: `Datum`
        },
        add: `Erstellen`,
        error: {
            get: `Une erreur est survenue à la récupération des rendez-vous`,//TO-TRANSLATE
            create: `Une erreur est survenue à l'ajout du rendez-vous`,//TO-TRANSLATE
            update: `Une erreur est survenue à la mise à jour du rendez-vous`,//TO-TRANSLATE
            delete: `Une erreur est survenue à la suppression du rendez-vous`//TO-TRANSLATE
        }
    },
    users: {
        title: `Kontakte`,
        new: {
            title: `Nutzer einladen`,
            invite: `Einladen`
        },
        me: `Ich`,
        error: {
            get: `Les contacts ne sont pas accessibles pour le moment`,//TO-TRANSLATE
            create: `Une erreur est survenue à l'invitation du contact`//TO-TRANSLATE
        }
    },
    messages: {
        title: `Benachrichtigungen`,
        add: {
            title: `Nouveau message`, //TO-TRANSLATE
            subject: `Sujet`, //TO-TRANSLATE
            body: `Corps du message` //TO-TRANSLATE
        },
        edit: {
            title: `Modifier le message`, //TO-TRANSLATE
            updatedAt: `Envoyé le {{date}} à {{time}}`, //TO-TRANSLATE
            readAt: `Lu le {{date}} à {{time}}`, //TO-TRANSLATE
            notRead: `Non lu` //TO-TRANSLATE
        },
        delete: {
            title: `Supprimer le message` //TO-TRANSLATE
        },
        empty: `Aucun message n'a été envoyé à {{firstname}}, cliquez sur <0>"Nouveau message"</0> dès à présent !`, //TO-TRANSLATE
        emptyDefault: `votre proche`, //TO-TRANSLATE
        error: {
            get: `Une erreur est survenue à la récupération des messages`, //TO-TRANSLATE
            send: `Une erreur est survenue à l'envoie du message`, //TO-TRANSLATE
            update: `Une erreur est survenue à la mise à jour du message`, //TO-TRANSLATE
            delete: `Une erreur est survenue à la suppression du message` //TO-TRANSLATE
        }
    },
    visio: {
        title: `Videoanruf`,
        copy: `Einladungslink kopieren`,
        error: {
            get: `L'appel vidéo n'est pas disponible pour le moment`//TO-TRANSLATE
        }
    },
    profile: {
        title: `Mein Profil`,
        save: `Speichern`,
        password: `Mein Passwort`,
        error: {
            update: `Une erreur est survenue à la mise à jour du profil`,//TO-TRANSLATE
            updatePwd: `Une erreur est survenue à la mise à jour du mot de passe`//TO-TRANSLATE
        }
    },
    invitation: {
        title: `Mein Konto erstellen`,
        save: `Erstellen`,
        error: {
            get: `Une erreur est survenue à la récupération du compte`,//TO-TRANSLATE
            update: `Une erreur est survenue à la mise à jour du compte`//TO-TRANSLATE
        }
    },
    login: {
        title: `Herzlich Willkommen beim Kissen-Viktor`,
        text: `Bitte melden Sie sich an.`,
        button: `Anmelden`,
        forgotPwd: `Passwort vergessen`,
        error: {
            login: `Une erreur est survenue à la connexion`//TO-TRANSLATE
        }
    },
    forgotPwd: {
        title: `Passwort vergessen`,
        button: `Zurücksetzen`,
        text: `Sie erhalten in wenigen Augenblicken einen Link zum Zurücksetzen, wenn ihre E-Mail Adresse korrekt registriert wurde.`,
        error: {
            send: `Une erreur est survenue à la demande de réinitialisation`//TO-TRANSLATE
        }
    },
    resetPwd: {
        title: `Passwort zurücksetzen`,
        button: `Zurücksetzen`,
        error: {
            get: `Une erreur est survenue à la récupération du compte invité`,//TO-TRANSLATE
            update: `Une erreur est survenue à la mise à jour du mot de passe`//TO-TRANSLATE
        }
    },
    settings: {
        title: `Paramètres`,//TO-TRANSLATE
        notifications: {
            title: `Notifications`,//TO-TRANSLATE
            text: `La box installée chez votre proche reçoit parfois des notifications. 
            Ça peut-être des notifications d'appels vidéo, des rappels de RDV, des alertes importantes du département, ou une confirmation de lancement du SOS. 
            Vous pouvez configurer la plage horaire sur laquelle votre proche peut recevoir ces notifications, afin de ne pas la déranger à des moments innoportuns.`,//TO-TRANSLATE
            time: `Plage horaire active (pendant laquelle le proche va recevoir les notifications)`,//TO-TRANSLATE
            mute: `Mode "ne pas déranger" (votre proche ne recevra jamais les notifications)`,//TO-TRANSLATE
            error: {
                get: `Une erreur est survenue à la récupération des informations du patient`,//TO-TRANSLATE
                update: `Une erreur est survenue à la mise à jour des notifications`,//TO-TRANSLATE
            }
        },
        sosContact: {
            title: `Configuration SOS`,//TO-TRANSLATE
            text: `La Box Viktor vous permet de faire une demande de contact par l'envoi d'un mail à vos proches.`,//TO-TRANSLATE
            add: {
                title: `Nouveau contact SOS`//TO-TRANSLATE
            },
            edit: {
                title: `Modifier le contact SOS`,//TO-TRANSLATE
            },
            delete: {
                title: `Supprimer le contact SOS`//TO-TRANSLATE
            },
            error: {
                get: `Une erreur est survenue à la récupération des contacts SOS`,//TO-TRANSLATE
                add: `Une erreur est survenue à l'ajout du contact SOS`,//TO-TRANSLATE
                update: `Une erreur est survenue à la mise à jour du contact SOS`,//TO-TRANSLATE
                delete: `Une erreur est survenue à la suppression du contact SOS`//TO-TRANSLATE
            }
        }
    },
    form: {
        phone: {
            label: `Telefon`,
            helper: `Die Telefonnummer muss die Landesvorwahl enthalten (+491022030405)`
        },
        mail: {
            label: `E-Mail`,
            helper: `Die E-Mail-Adresse ist erforderlich und muss gültig sein (beispiel@beispiel.com)`
        },
        password: {
            label: `Passwort`,
            helper: `Das Passwort ist erforderlich und muss 8 Zeichen enthalten. Unter anderem: 1 Zahl, 1 Großbuchstabe, 1 Kleinbuchstabe`
        },
        newPassword: {
            label: `Neues Passwort`,
            helper: `Das neue Passwort ist erforderlich und muss 8 Zeichen enthalten. Unter anderem: 1 Zahl, 1 Großbuchstabe, 1 Kleinbuchstabe`
        },
        confirmedNewPassword: {
            label: `Neues Passwort bestätigen`,
            helper: `Die Passwörter stimmen nicht überein`,
        },
        firstname: {
            label: `Vorname`,
            helper: `Der Vorname ist erforderlich`
        },
        lastname: {
            label: `Nachname`,
            helper: `Der Nachname ist erforderlich`
        },
        startAt: {
            label: `Beginn`
        },
        endAt: {
            label: `Ende`
        }
    },
    actions: {
        create: `Erstellen`,
        edit: `Bearbeiten`,
        yes: `Ja`,
        no: `Nein`,
        areYouSure: `Sind Sie sicher?`,
        goBack: `Zurück`,
        goBackMedias: `Zurück zu den Medien`,
    },
    navbar: {
        login: `Anmelden`,
        logout: `Abmelden`,
        profile: `Mein Profil`,
        userMenu: {
            tooltip: `Mein Konto`
        }
    },
    footer: {
        copyright: `Fabriqué avec <0/> par Fingertips`//TO-TRANSLATE
    },
    error: {
        default: `Une erreur est survenue`//TO-TRANSLATE
    }
}
