import React, { useState } from 'react';
import "./DialogAddMusic.scss";
import {
    Dialog, DialogContent, DialogContentText, Button, TextField,
    useMediaQuery
} from '@mui/material/';
import DialogTitle from '../DialogTitle/DialogTitle';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'

export default function DialogAddMusic(props) {
    //const matches = useMediaQuery('(max-width:780px)');
    const { open, setOpen, onClick, title, editInfo } = props;
    const [name, setName] = useState((!!editInfo && editInfo.name) ? editInfo.name : "");
    const [artist, setArtist] = useState((!!editInfo && editInfo.artist) ? editInfo.artist : "");
    const [url, setUrl] = useState((!!editInfo && editInfo.url) ? editInfo.url : "");
    const matches = useMediaQuery('(max-width:780px)');
    const { t } = useTranslation();

    /*useEffect(() => {
        if (!!editInfo) {
            if (!_.isEmpty(editInfo.name)) setName(editInfo.name)
            if (editInfo.isPrivate) setIsPrivate(editInfo.isPrivate)
            if (!_.isEmpty(editInfo.previewUrl)) setPreviewUrl(editInfo.previewUrl)
        }
    }, [editInfo])*/

    return (
        <Dialog open={open} onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth={'sm'}
            fullScreen={matches}
            className="dialogAddMusicContainer"
        >
            <DialogTitle title={title} onClose={() => setOpen(false)} />
            <DialogContent>
                <DialogContentText>
                    <TextField label={t('musics.add.name')} variant="filled"
                        required={true}
                        fullWidth={true}
                        value={name}
                        className="input"
                        onChange={(e) => setName(e.target.value)} />
                    <TextField label={t('musics.add.artist')} variant="filled"
                        required={true}
                        fullWidth={true}
                        value={artist}
                        className="input"
                        onChange={(e) => setArtist(e.target.value)} />
                    <TextField label={t('musics.add.url')} variant="filled"
                        required={true}
                        fullWidth={true}
                        value={url}
                        className="input"
                        onChange={(e) => setUrl(e.target.value)} />

                    <div className="button">
                        <Button variant="contained" disabled={_.isEmpty(name) || _.isEmpty(artist) || _.isEmpty(url)} color="primary" onClick={() => onClick(name, artist, url)}>{editInfo ? t('actions.edit') : t('actions.create')}</Button>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog >
    );
}
