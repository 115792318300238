import { getLang } from "../i18n";

export const RESET_PASSWORD = "ZWQtUHJvdG8gaHR0cHM7CiAgICAgICAgICAgIHByb3h5X3JlZGlyZWN0IG9mZjsKICAgICAgICAg";
export const REGEX_MAIL = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$')
export const REGEX_PHONE = new RegExp('\\+[0-9]{2}(?=.*[0-9]).{9,10}$')
export const REGEX_PASSWORD = new RegExp("(?=.*[a-zA-Z])(?=.*[0-9]).{8,}");
export const JITSI_DOMAIN = "meet.jit.si/";
export const JITSI_PWD = "8qHXSpFLs3oJ6ynvIWG1";
export const API_URL = `https://${window.env.REACT_APP_API_URL}.${getLang()}/api/user/v1`
export const PUBLIC_URL = `https://${window.env.REACT_APP_URL}`
export const NODE_ENV = "production"