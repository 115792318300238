import { createSlice } from '@reduxjs/toolkit'

const snackBarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    severity: "warning",
    message: "error.default",
    open: false
  },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setInfo: (state, action) => {
      state.open = action.payload.open || false;
      state.message = action.payload.message || "error.default";
      state.severity = action.payload.severity || "warning";
    },
  },
})

// Action creators are generated for each case reducer function
export const { setOpen, setInfo } = snackBarSlice.actions

export default snackBarSlice.reducer