import './App.scss';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from './pages/login/LoginPage';
import HomePage from './pages/home/HomePage';
import MediasPage from './pages/medias/MediasPage';
import PhotosPage from './pages/photos/PhotosPage';
import Header from './components/header/Header';
import MusicsPage from './pages/musics/MusicsPage';
import MoviesPage from './pages/movies/MoviesPage';
import EventsPage from './pages/events/EventsPage';
import UsersPage from './pages/users/UsersPage';
import MessagesPage from './pages/messages/MessagesPage';
import VisioPage from './pages/visio/VisioPage';
import ProfilePage from './pages/profile/ProfilePage';
import InvitationPage from './pages/invitation/InvitationPage';
import ForgotPasswordPage from './pages/forgotPassword/ForgotPasswordPage';
import ResetPasswordPage from './pages/resetPassword/ResetPasswordPage';
import SettingsPage from './pages/settings/SettingsPage';
import Footer from './components/footer/Footer';
import { ThemeProvider } from "@mui/material";

import PrivateRoute from './components/routes/PrivateRoute';
import GuestRoute from './components/routes/GuestRoute';
import theme from './theme'
import SnackBar from './components/snackbar/Snackbar';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div id="page-container">
        <Router>
          <Header />
          <div id="content-wrap">
            <Routes>
              <Route index path="login" element={<GuestRoute> <LoginPage />  </GuestRoute>} />
              <Route path="/" element={<PrivateRoute> <HomePage />  </PrivateRoute>} />
              <Route path="medias" element={<PrivateRoute> <MediasPage />  </PrivateRoute>} />
              <Route path="photos/:id" element={<PrivateRoute> <PhotosPage />  </PrivateRoute>} />
              <Route path="musics/:id" element={<PrivateRoute> <MusicsPage />  </PrivateRoute>} />
              <Route path="movies/:id" element={<PrivateRoute> <MoviesPage />  </PrivateRoute>} />
              <Route path="events" element={<PrivateRoute> <EventsPage />  </PrivateRoute>} />
              <Route path="users" element={<PrivateRoute> <UsersPage />  </PrivateRoute>} />
              <Route path="messages" element={<PrivateRoute> <MessagesPage />  </PrivateRoute>} />
              <Route path="visio" element={<PrivateRoute> <VisioPage />  </PrivateRoute>} />
              <Route path="profile" element={<PrivateRoute> <ProfilePage />  </PrivateRoute>} />
              <Route path="settings" element={<PrivateRoute> <SettingsPage />  </PrivateRoute>} />
              <Route path="users/invitation/:token" element={<GuestRoute> <InvitationPage />  </GuestRoute>} />
              <Route path="forgotPassword" element={<GuestRoute> <ForgotPasswordPage />  </GuestRoute>} />
              <Route path="users/reset_password/:token" element={<GuestRoute> <ResetPasswordPage />  </GuestRoute>} />
              
            </Routes>
            <SnackBar />
          </div>
          <Footer/>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
