import React, { useState, useEffect } from 'react';
import "./VisioPage.scss"
import { Grid/*, useTheme, useMediaQuery, Button*/ } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { KeyboardDoubleArrowLeft/*, ContentCopy*/ } from '@mui/icons-material/';
import Loading from '../../components/loading/Loading';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
//import Jitsi from 'react-jitsi'
import { JitsiMeeting } from '@jitsi/react-sdk';
import { getSenior } from '../../api/senior';
import { JITSI_DOMAIN, JITSI_PWD } from '../../config/index'
import { setInfo } from '../../store/snackbar';


export default function VisioPage() {
    const { t } = useTranslation();
    const loggedInUser = useSelector(state => state.user.loggedInUser);

    //const [onCall, setOnCall] = useState(true)
    const [senior, setSenior] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        let didCancel = false;

        window.scrollTo(0, 0);
        async function retrieveSenior() {
            try {
                let seniorTmp = await getSenior()
                !didCancel && setSenior(seniorTmp);
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({open: true, message: "visio.error.get"}))
                setIsLoading(false);
            }
        }

        Promise.all([retrieveSenior()])
        return () => {
            didCancel = true;
        };
    }, [])

    useEffect(() => {
        let didCancel = false;
        const script2 = document.createElement('script');
        if (senior) {
            let name = `${loggedInUser.firstname} ${loggedInUser.lastname || ""}`

            script2.type = 'text/javascript';
            script2.innerHTML = `etablishConnection("${senior.id}", "${name}"); `;

            document.body.appendChild(script2);
        }
        didCancel && document.body.removeChild(script2);
        return () => {
            didCancel = true;
        };
    }, [senior])


    /*const copyToClipboard = () => { navigator.clipboard.writeText(`https://${JITSI_DOMAIN}/viktor-ui-patient-${senior.id}`) };

    const handleAPI = (JitsiMeetAPI) => {
        JitsiMeetAPI.addEventListener('videoConferenceJoined', () => {
            console.log("videoConferenceJoined")
            setTimeout(() => {
                JitsiMeetAPI.executeCommand('password', 'Test@1234');
            }, 3000);
        });
    }*/
    
    if (isLoading) {
        return (<Loading />)
    }


    return (
        <div className="visioPage">
            <Grid container alignItems="center">
                <Grid item sm={3} xs={12}>
                    <Grid container justifyContent="flex-start" alignItems="center" className="goBack">
                        <KeyboardDoubleArrowLeft className="icon" />
                        <Link to="/">{t("actions.goBack")}</Link>
                    </Grid>
                </Grid>

                <Grid item sm={6} xs={12} className="title">
                    <h1>{t("visio.title")}</h1>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                {/*
                <Jitsi
                    domain={JITSI_DOMAIN}
                    roomName={`viktor-ui-patient-${senior.id}`}
                    displayName={`${loggedInUser.firstname} ${loggedInUser.lastName || ''}`}
                    //password={JITSI_PWD}
                    loadingComponent={Loading}
                    onIframeLoad={() => setOnCall(true)}
    />
             
                */}
                <JitsiMeeting
                    domain={JITSI_DOMAIN}
                    roomName={`viktor-ui-patient-${senior.id}`}
                    //roomName={""}
                    configOverwrite={{
                        disableModeratorIndicator: true,
                        prejoinPageEnabled: false
                    }}
                    interfaceConfigOverwrite={{
                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: false
                    }}
                    userInfo={{
                        displayName: `${loggedInUser.firstname} ${loggedInUser.lastName || ''}`
                    }}
                    /*onApiReady={(api) => {
                        // here you can attach custom event listeners to the Jitsi Meet External API
                        // you can also store it locally to execute commands
                        api.addEventListener("passwordRequired", () => {
                            console.log("passwordRequired 1")
                            api.executeCommand("password", JITSI_PWD);
                        });

                        api.on('videoConferenceJoined', () => {
                            console.log("videoConferenceJoined")
                            setTimeout(() => {
                                api.executeCommand('password', JITSI_PWD);
                            }, 200);
                        });
                        api.on('passwordRequired', function () {
                            console.log("passwordRequired 2")
                            setTimeout(function () {

                                api.executeCommand('password', JITSI_PWD);

                            }, 200)

                        });

                    }}*/
                    getIFrameRef={(iframeRef) => { iframeRef.style.height = '60vh'; iframeRef.style.width = '100%'; }}
                />
            </Grid>
            {/*
                onCall &&
                <Grid container justifyContent="center">
                    <div className="button">
                        <Button variant="contained"
                            color="primary"
                            onClick={copyToClipboard}
                            startIcon={<ContentCopy />}
                        >
                            {t('visio.copy')}
                        </Button>
                    </div>
                </Grid>
                */}
        </div >
    );
}