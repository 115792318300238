import React, { useState, useEffect, useRef } from 'react';
import "./MediasPage.scss"
import PictureSquare from '../../components/pictureSquare/PictureSquare';
import { Grid/*, useTheme*/, useMediaQuery } from "@mui/material";
import _ from 'lodash'
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, KeyboardDoubleArrowLeft } from '@mui/icons-material/';
import { useTranslation, Trans } from 'react-i18next';
import { getPhotoAlbums, deletePhotoAlbums, createPhotoAlbum } from '../../api/photos'
import { getMusicLists, deleteMusicList, createMusicList } from '../../api/musics'
import { getAlbumsMovies, deleteMovieList, createMovieList } from '../../api/movies'
import { useSelector, useDispatch } from 'react-redux';
import DialogAddAlbum from '../../components/dialog/DialogAddAlbum/DialogAddAlbum';
import Loading from '../../components/loading/Loading';
import { Link } from 'react-router-dom';
import AddCardSquare from '../../components/addCardSquare/AddCardSquare';
import { setInfo } from '../../store/snackbar';

export default function MediasPage() {
    const matches = useMediaQuery('(max-width:780px)');
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loggedInUser = useSelector(state => state.user.loggedInUser);

    const limitToSeeMore = matches ? 3 : 4;
    const limitAlbums = 100;
    //const theme = useTheme();
    const [seeMore, setSeeMore] = useState([false, false, false]);
    const [albumPhotos, setAlbumPhotos] = useState([]);
    const [albumMusics, setAlbumMusics] = useState([]);
    const [albumMovies, setAlbumMovies] = useState([]);

    const [albumSections, setAlbumSections] = useState([{
        title: t("medias.list.photos"),
        data: albumPhotos,
        ref: useRef(null),
        type: 'photos',
        onClick: () => setAddAlbumPhoto(true),
        delete: t("medias.deleteAlbum.photos")
    }, {
        title: t("medias.list.musics"),
        data: albumMusics,
        ref: useRef(null),
        type: 'musics',
        onClick: () => setAddAlbumMusic(true),
        delete: t("medias.deleteAlbum.musics")
    }, {
        title: t("medias.list.movies"),
        data: albumMovies,
        ref: useRef(null),
        type: 'movies',
        onClick: () => setAddAlbumMovie(true),
        delete: t("medias.deleteAlbum.movies")
    }]);
    const [isLoading, setIsLoading] = useState(true);

    const [addAlbumPhoto, setAddAlbumPhoto] = useState(false);
    const [addAlbumMusic, setAddAlbumMusic] = useState(false);
    const [addAlbumMovie, setAddAlbumMovie] = useState(false);

    const [areYouSure, setAreYouSure] = useState(false);

    const updatePhotoAlbums = async () => {
        let albumPhotosTmp = await getPhotoAlbums();
        let albumSectionsTmp = [...albumSections];
        albumSectionsTmp[albumSectionsTmp.findIndex((el) => el.type === "photos")].data = albumPhotosTmp;
        setAlbumPhotos(albumPhotosTmp)
        setAlbumSections(albumSectionsTmp)
    }

    const updateMusicLists = async () => {
        let musicsTmp = await getMusicLists();
        setAlbumMusics(musicsTmp)
        let albumSectionsTmp = [...albumSections];
        albumSectionsTmp[albumSectionsTmp.findIndex((el) => el.type === "musics")].data = musicsTmp;
        setAlbumSections(albumSectionsTmp)
    }

    const updateMovieLists = async () => {
        let moviesTmp = await getAlbumsMovies();
        setAlbumMovies(await getAlbumsMovies())
        let albumSectionsTmp = [...albumSections];
        albumSectionsTmp[albumSectionsTmp.findIndex((el) => el.type === "movies")].data = moviesTmp;
        setAlbumSections(albumSectionsTmp)
    }

    useEffect(() => {
        let didCancel = false;
        window.scrollTo(0, 0);

        async function retrievePhotos() {
            try {
                !didCancel && await updatePhotoAlbums();
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "medias.error.getPhotoAlbums" }))
                setIsLoading(true);
            }
        }

        async function retrieveMusics() {
            try {
                !didCancel && await updateMusicLists();
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "medias.error.getMusicLists" }))
                setIsLoading(true);
            }
        }

        async function retrieveMovies() {
            try {
                !didCancel && await updateMovieLists();
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "medias.error.getMovieLists" }))
                setIsLoading(true);
            }
        }

        Promise.all([retrievePhotos(), retrieveMusics(), retrieveMovies()])

        return () => { didCancel = true; };
    }, [])

    const toOpenAreYouSure = (val) => {
        setAreYouSure(val)
    }

    const deleteMedia = async (id, type) => {
        setIsLoading(true);
        try {
            switch (type) {
                case "photos":
                    await deletePhotoAlbums(id)
                    await updatePhotoAlbums();
                    break;
                case "musics":
                    await deleteMusicList(id)
                    await updateMusicLists();
                    break;
                case "movies":
                    await deleteMovieList(id)
                    await updateMovieLists();
                    break;
                default:
                    break;
            }
        } catch (e) {
            dispatch(setInfo({ open: true, message: `medias.error.delete_${type}` }))
        }
        setIsLoading(false);

    }

    const onAddAlbumPhoto = async (name, isPrivate) => {
        setIsLoading(true);
        try {
            await createPhotoAlbum({ name, is_private: isPrivate })
            await updatePhotoAlbums();
            setAddAlbumPhoto(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "medias.error.addPhotoAlbum" }))
        }
        setIsLoading(false);
    }


    const onAddMusicList = async (name, isPrivate, file) => {
        setIsLoading(true);
        try {
            await createMusicList({ name, media: file })
            await updateMusicLists();
            setAddAlbumMusic(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "medias.error.addMusicList" }))
        }
        setIsLoading(false);
    }


    const onAddMovieList = async (name, isPrivate, file) => {
        setIsLoading(true);
        try {
            await createMovieList({ name, media: file })
            await updateMovieLists();
            setAddAlbumMovie(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "medias.error.addMovieList" }))
        }
        setIsLoading(false);
    }

    const albumComponent = (album) => {
        return album.data.map((el, i) => {
            return (<Grid item sm={2} xs={6} key={`media_${album.type}_${i}`} container direction="column">
                <PictureSquare {...el} link={`/${album.type}/${el.id}`} ableDelete={el.user?.id.toString() === loggedInUser.id.toString()}
                    onDelete={{ title: album.delete, content: t("actions.areYouSure"), onClick: () => deleteMedia(el.id, album.type), setOpen: toOpenAreYouSure, open: areYouSure }} />
                <Grid item style={{ width: "inherit" }}>
                    <p className="titleAlbum">{el.name}</p>
                    <p className="countAlbum">
                        <Trans i18nKey="medias.album.count"
                            values={{ count: el.size }}
                            components={[<br />]} />
                        {el.is_private && ` - ${t("medias.album.private")}`}
                    </p>
                </Grid>
            </Grid>)
        }
        );
    };

    const addPhoto = (onClick) => {
        return (<Grid item sm={2} xs={6} container direction="column" className="addAlbum" onClick={onClick}>
            <AddCardSquare onClick={onClick} />
        </Grid>)
    }

    const fullAlbumDesktop = (album, section) => {
        let chunkedArray = _.chunk(album.length < limitAlbums ? [addPhoto(section.onClick), ...album] : album, 5);
        if (chunkedArray[chunkedArray.length - 1].length < 5) {
            chunkedArray[chunkedArray.length - 1] = _.concat(chunkedArray[chunkedArray.length - 1], Array(5 - chunkedArray[chunkedArray.length - 1].length).fill(<Grid item sm={2} xs={6}></Grid>))
        }
        return chunkedArray.map((subArr, i) => (
            <Grid container justifyContent="space-between" spacing={matches ? 1 : 2} style={{ marginBottom: "20px" }} key={`section_${album.type}_${i}`}>
                {
                    subArr.map(el => el)
                }
            </Grid>)
        )
    }

    const fullAlbumMobile = (album, section) => {
        let albumTmp = album.length < limitAlbums ? [addPhoto(section.onClick), ...album] : album;
        if (albumTmp % 2 !== 0) {
            albumTmp = [...albumTmp, <Grid item sm={2} xs={6}></Grid>]
        }

        return (
            <Grid container justifyContent="flex-start" spacing={matches ? 1 : 2} style={{ marginBottom: "20px" }}>
                {
                    albumTmp.map(el => el)
                }
            </Grid>)
    }

    const firstLineAlbum = (album, section) => {
        return (
            album.length >= limitToSeeMore ?
                <>
                    {addPhoto(section.onClick)}
                    {_.slice(album, 0, limitToSeeMore).map((el, i) => el)}
                </>
                :
                <>
                    {_.concat(
                        addPhoto(section.onClick),
                        album,
                        Array(limitToSeeMore - album.length).fill(<Grid item sm={2} xs={6}></Grid>)
                    ).map((el, i) => el)}

                </>
        )
    }

    const executeScrollPos = (ref) => ref.current.scrollIntoView(false)

    const toogleSeeMore = (el, i) => {
        if (seeMore[i]) {
            executeScrollPos(el.ref)
        }
        let seeMoreTmp = [...seeMore];
        seeMoreTmp[i] = !seeMoreTmp[i]
        setSeeMore([...seeMoreTmp])

    }
    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div className="mediasPage">
            <Grid container alignItems="center">
                <Grid item sm={3} xs={12}>
                    <Grid container justifyContent="flex-start" alignItems="center" className="goBack">
                        <KeyboardDoubleArrowLeft className="icon" />
                        <Link to="/">{t("actions.goBack")}</Link>
                    </Grid>
                </Grid>

                <Grid item sm={6} xs={12} className="title">
                    <h1>{t("medias.title")}</h1>
                </Grid>




            </Grid>

            <DialogAddAlbum open={addAlbumPhoto} setOpen={setAddAlbumPhoto} title={t('medias.addAlbum.photos')} privateCheck={true} createAlbum={onAddAlbumPhoto} />
            <DialogAddAlbum open={addAlbumMusic} setOpen={setAddAlbumMusic} title={t('medias.addAlbum.musics')} enabledPhoto={true} createAlbum={onAddMusicList} />
            <DialogAddAlbum open={addAlbumMovie} setOpen={setAddAlbumMovie} title={t('medias.addAlbum.movies')} enabledPhoto={true} createAlbum={onAddMovieList} />
            {
                albumSections.map((el, i) =>
                    <div key={el.title}>
                        <h2 className="sectionTitle" ref={el.ref}>{el.title}</h2>
                        {
                            seeMore[i] ?
                                <>
                                    {
                                        !matches ? fullAlbumDesktop(albumComponent(el), el) : fullAlbumMobile(albumComponent(el), el)
                                    }
                                    <Grid container justifyContent="flex-end" alignItems="center" className="seeActionSection" style={{ paddingBottom: (i === 2) ? "2.5em" : "auto" }}>
                                        <KeyboardDoubleArrowUp className="icon" />
                                        <p className="seeAction" onClick={() => toogleSeeMore(el, i)}>{t("medias.list.seeLess")}</p>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid container justifyContent="space-between" spacing={matches ? 1 : 2} >
                                        {firstLineAlbum(albumComponent(el), el)}
                                    </Grid>
                                    {el.data.length > limitToSeeMore &&
                                        <Grid container justifyContent="flex-end" alignItems="center" className="seeActionSection" style={{ paddingBottom: (i === 2) ? "2.5em" : "auto" }}>
                                            <KeyboardDoubleArrowDown className="icon" />
                                            <p className="seeAction" onClick={() => toogleSeeMore(el, i)}>{t("medias.list.seeMore")}</p>
                                        </Grid>
                                    }
                                </>
                        }
                    </div>)
            }
        </div >
    );
}