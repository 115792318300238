import axios from 'axios';
import { API_URL } from '../config';

export const getPhotoAlbums = async () => {
  try {
    const response = await axios.get(`${API_URL}/photo_albums`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
        }
    })
    return response.data.photo_albums;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getPhotos = async ({id}) => {
  try {
    const response = await axios.get(`${API_URL}/photo_albums/${id}/photos`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
        }
    })
    return response.data.photos;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const deletePhotoAlbums = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/photo_albums/${id}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
        }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const createPhotoAlbum = async (newPhotoAlbum) => {
  try {
    const response = await axios.post(`${API_URL}/photo_albums`, newPhotoAlbum, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const updatePhotoAlbum = async (photoAlbumData, id) => {
  try {
    const response = await axios.put(`${API_URL}/photo_albums/${id}`, photoAlbumData, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const createPhoto = async (newPhoto, albumId) => {
  try {
    const response = await axios.post(`${API_URL}/photo_albums/${albumId}`, newPhoto, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const deletePhoto = async (id, albumId) => {
  try {
    const response = await axios.delete(`${API_URL}/photo_albums/${albumId}/${id}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
        }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}