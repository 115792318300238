import * as React from 'react';
import './UserCardRectangle.scss';
import { Grid, Avatar, Divider } from "@mui/material";
import { Edit } from '@mui/icons-material/';
import { useNavigate } from "react-router-dom";

export default function UserCardRectangle(props) {
  //const theme = useTheme();
  const { user, disabledAvatar, edit } = props;
  let navigate = useNavigate();

  const goToProfile = () => {
    navigate('/profile');
  }

  return (
    <span className="userCardRectangleContainer">
      <Grid item xs={12} key={`user_${user.id}`}
        container direction="row" className="userCard" alignItems="center" justifyContent="space-between">
        {!disabledAvatar && <Grid item xs={2}>
          <Avatar alt={`${user.firstname} ${user.lastname}`} src={user.avatar} className="avatar" />
        </Grid>
        }
        <Grid item className="info" xs={!disabledAvatar ? 8 : 10}>
          <p className="name">{user.firstname} {user.lastname}</p>
          <p className="email">{user.email}</p>
        </Grid>
        <Grid item xs={2} className="moreMobile">
          {edit &&
            <Edit onClick={goToProfile} />
          }
        </Grid>
      </Grid>
      <Divider />
    </span>
  )
}