import ReactDOM from "react-dom/client";
import './index.css'
import App from './App';
import './i18n';
import { store } from './store'
import { logout, login } from "./store/user";
import { Provider } from 'react-redux'
import { getUserLogged } from "./api/users";
import Loading from "./components/loading/Loading";
import _ from 'lodash';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

let isLoading = true;

const setCurrentLoggedInUser = async () => {
  const token = localStorage.getItem('token');
  if (token && !_.isEmpty(token)) {
    try {
      let user = await getUserLogged();

      if (user) {
        store.dispatch(login(user));
      } else {
        localStorage.removeItem('token');
        store.dispatch(logout())
      }
    } catch (e) {
      localStorage.removeItem('token');
      store.dispatch(logout())
    }
  } else {
    localStorage.removeItem('token');
    store.dispatch(logout())
  }
  isLoading = false;
  root.render(
    <Provider store={store}>
      {isLoading ? <Loading/> : <App />}
    </Provider>
  );
}

Promise.all([setCurrentLoggedInUser()])

serviceWorkerRegistration.register();