import React, { useState } from 'react';
import {
    Dialog, DialogContent, DialogContentText, useMediaQuery
} from '@mui/material/';
import DialogTitle from '../DialogTitle/DialogTitle';
import { getHours, getMinutes } from 'date-fns';
import DialogEvent from '../DialogEvent/DialogEvent';
import { useTranslation } from 'react-i18next';
import "./DialogAllEventDay.scss"
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import * as localizedFormat from 'dayjs/plugin/localizedFormat'

export default function DialogAllEventDay(props) {
    const matches = useMediaQuery('(max-width:780px)');
    const { t, i18n } = useTranslation();
    dayjs.locale(i18n.language);
    dayjs.extend(localizedFormat);
    const { open, setOpen, eventsOfDay, onDelete, onUpdate } = props;
    const [openDetailEvent, setOpenDetailEvent] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const detailEvent = (event) => {
        setSelectedEvent(event);
        setOpenDetailEvent(true);
    }

    const onUpdateEvent = async (editedEvent) => {
        await onUpdate(editedEvent);
        setOpenDetailEvent(false);
    }


    return (
        <>
            {openDetailEvent && !!selectedEvent &&
                <DialogEvent open={openDetailEvent} setOpen={setOpenDetailEvent} title={t('events.edit.title')}
                    onClick={onUpdateEvent} event={selectedEvent} editInfo={true} onDelete={onDelete} />}

            <Dialog open={open} onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth={'sm'}
                fullScreen={matches}
                className="dialogAllEventDay"
            >
                <DialogTitle title={t('events.all.title')} onClose={() => setOpen(false)} />
                <DialogContent>
                    <DialogContentText>
                        <div className="eventsOfDay">
                            <h3>{dayjs(eventsOfDay[0].start_at).format('L').toString()}</h3>
                            {
                                eventsOfDay.sort((a, b) => {
                                    return new Date(a.start_at) - new Date(b.start_at)
                                }).map((e) => <p key={`event_${e.id}`} className="anEvent" onClick={() => detailEvent(e)}>
                                    <span className="hours">
                                        {getHours(new Date(e.start_at)) <= 9 ?
                                            `0${getHours(new Date(e.start_at))}`
                                            :
                                            getHours(new Date(e.start_at))}
                                        h
                                        {getMinutes(new Date(e.start_at)) <= 9 ?
                                            `0${getMinutes(new Date(e.start_at))}`
                                            :
                                            getMinutes(new Date(e.start_at))
                                        }
                                    </span>
                                    {e.name}
                                </p>)
                            }
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog >
        </>
    );
}
