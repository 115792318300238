import React, { useState, useEffect } from 'react';
import { Paper, Grid, TextField, Button } from '@mui/material'
import { useTranslation } from 'react-i18next';
import "./LoginPage.scss";
import { getToken, getUserLogged } from '../../api/users';
import { useNavigate, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { REGEX_PASSWORD, REGEX_MAIL } from '../../config';
//import _ from 'lodash';
import { login } from '../../store/user';
import { setInfo } from '../../store/snackbar';

export default function LoginPage(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const onKeyPressMail = (e) => {
        if (e.key === 'Enter' && REGEX_MAIL.test(mail.trim())) {
            //props.loginClick(mail)
        }
    }

    const onKeyPressPwd = (e) => {
        if (e.key === 'Enter' && REGEX_PASSWORD.test(password) && REGEX_MAIL.test(mail.trim())) {
            //handleLoginAttempt(e)
        }
    }

    const handleLoginAttempt = async () => {
        try {
            await getToken(mail, password);
            let user = await getUserLogged();
            dispatch(login(user));
            navigate('/medias')
        }
        catch (e) {
            dispatch(setInfo({ open: true, message: "login.error.login" }))
        }

    }

    return (
        <Grid container justifyContent="center" alignItems="center" className="loginPage">
            <Grid item xs={12} sm={8} >
                <Paper className="paper">
                    <h1>{t('login.title')}</h1>
                    <p>{t('login.text')}</p>
                    <TextField
                        id="mail"
                        label={t('form.mail.label')}
                        value={mail}
                        onChange={(event) => setMail(event.target.value)}
                        fullWidth
                        required
                        margin="normal"
                        className="input"
                        helperText={!!mail.trim() && !REGEX_MAIL.test(mail.trim()) ? t('form.mail.helper') : ''}
                        error={!!mail.trim() && !REGEX_MAIL.test(mail.trim())}
                        onKeyPress={onKeyPressMail}
                        variant="outlined"
                        autoFocus={true}
                    />
                    <TextField
                        id="password"
                        label={t('form.password.label')}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        fullWidth
                        required
                        margin="normal"
                        className="input"
                        helperText={!!password && !REGEX_PASSWORD.test(password) ? t('form.password.helper') : ''}
                        error={!REGEX_PASSWORD.test(password)}
                        onKeyPress={onKeyPressPwd}
                        variant="outlined"
                        type="password"
                    />
                    <div style={{ textAlign: "center" }}>
                        <Button disabled={
                            !password
                            || (!!password && !REGEX_PASSWORD.test(password))
                            || !mail
                            || (!!mail.trim() && !REGEX_MAIL.test(mail.trim()))
                        }
                            variant="contained"
                            onClick={handleLoginAttempt}
                        >
                            {t('login.button')}
                        </Button>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <NavLink to="/forgotPassword" className="link">
                            {t('login.forgotPwd')}
                        </NavLink>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    )
}