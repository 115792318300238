import axios from 'axios';
import { API_URL } from '../config';

export const getSenior = async () => {
  try {
    const response = await axios.get(`${API_URL}/senior`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateSenior = async (data) => {
  try {
    const response = await axios.put(`${API_URL}/senior`, data, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data;
  } catch (error) {
    throw error;
  }
}