import React, { useState, useEffect } from 'react';
import "./DialogAddAlbum.scss";
import {
    Dialog, DialogContent, DialogContentText, Button, TextField,
    FormGroup, FormControlLabel, Checkbox, useMediaQuery, IconButton
} from '@mui/material/';
import DialogTitle from '../DialogTitle/DialogTitle';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'
import { PhotoCamera, Delete } from '@mui/icons-material/';
import AddCardSquare from '../../addCardSquare/AddCardSquare';

export default function DialogAddAlbum(props) {
    //const matches = useMediaQuery('(max-width:780px)');
    const { open, setOpen, createAlbum, title, privateCheck, enabledPhoto, editInfo } = props;
    const [name, setName] = useState('');
    const [isPrivate, setIsPrivate] = useState((!!editInfo && editInfo.isPrivate) ? editInfo.isPrivate : false);
    const [previewUrl, setPreviewUrl] = useState((!!editInfo && editInfo.previewUrl) ? editInfo.previewUrl : "");
    const matches = useMediaQuery('(max-width:780px)');
    const { t } = useTranslation();

    useEffect(() => {
        if (!!editInfo) {
            if (!_.isEmpty(editInfo.name)) setName(editInfo.name)
            if (editInfo.isPrivate) setIsPrivate(editInfo.isPrivate)
            if (!_.isEmpty(editInfo.previewUrl)) setPreviewUrl(editInfo.previewUrl)
        }
    }, [editInfo])

    const handleChangePicture = async (event) => {
        const {
            target: {
                files: [file],
            }
        } = event;

        var reader = new FileReader();

        reader.onload = function (e) {
            setPreviewUrl(e.target.result);
        }

        reader.readAsDataURL(file); // convert to base64 string
    };

    const deleteFile = () => {
        setPreviewUrl("");
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth={'sm'}
            fullScreen={matches}
            className="dialogAddAlbumContainer"
        >
            <DialogTitle title={title} onClose={() => setOpen(false)} />
            <DialogContent>
                <DialogContentText>
                    <TextField id="filled-basic" label={t('medias.addAlbum.label.name')} variant="filled"
                        required={true}
                        fullWidth={true}
                        value={name}
                        onChange={(e) => setName(e.target.value)} />
                    {privateCheck &&
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={isPrivate} onChange={(e) => setIsPrivate(e.target.checked)} />} label={t('medias.addAlbum.label.private')} className="checkbox" />
                        </FormGroup>
                    }
                    {
                        enabledPhoto && _.isEmpty(previewUrl) &&
                        <>
                            <div style={{ width: "30%", margin: "20px auto" }}>
                                <input accept="image/*" style={{ display: "none" }} id="icon-button-file" type="file" onChange={event => handleChangePicture(event)} />
                                <label htmlFor="icon-button-file">
                                    <AddCardSquare />
                                </label>
                            </div>
                            <p className="addPhoto">{t("medias.addAlbum.addPhoto")}</p>
                        </>

                    }
                    {
                        enabledPhoto && !_.isEmpty(previewUrl) &&
                        <div style={{ textAlign: "center", margin: "20px auto" }}>
                            <img src={previewUrl} className="imgPreview" alt="" />
                            <div>
                                <input accept="image/*" style={{ display: "none" }} id="icon-button-file" type="file" onChange={event => handleChangePicture(event)} />
                                <label htmlFor="icon-button-file">
                                    <IconButton color="primary" component="span">
                                        <PhotoCamera />{t("medias.addAlbum.updatePhoto")}
                                    </IconButton>
                                </label>
                                <IconButton color="primary" component="span" onClick={deleteFile}>
                                    <Delete />{t("medias.addAlbum.deletePhoto")}
                                </IconButton>
                            </div>
                        </div>
                    }
                    <div className="button">
                        <Button variant="contained" disabled={_.isEmpty(name)} color="primary" onClick={() => createAlbum(name, isPrivate, previewUrl)}>{editInfo ? t('actions.edit') : t('actions.create')}</Button>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog >
    );
}
