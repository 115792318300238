import * as React from 'react';
import './Carousel.scss'
import Slider from "react-slick";


export default function Carousel(props) {
    const {index, carouselComponent} = props;

    var settings = {
        dots: false,
        initialSlide: index || 0
    };
    return (
        <div className="carouselContainer">
        <Slider {...settings}>
          {carouselComponent}
        </Slider>
      </div>
    );
}
