import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material/';
import { Close } from '@mui/icons-material/';
import './DialogTitle.scss'

export default function DialogTitle(props) {
  const { title, onClose } = props;
  return (
    <MuiDialogTitle className="dialogTitleContainer">
      {title}
      {onClose ? (
        <IconButton aria-label="close" className="closeButton" onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>

  );
}