import * as React from 'react';
import './CardSquare.scss';
import { Grid/*, useTheme*/ } from "@mui/material";

import { NavLink } from "react-router-dom";

export default function CardSquare(props) {
  //const theme = useTheme();

  return (
    <span className="cardSquareContainer">
      <NavLink
        to={props.link}
        className={({ isActive }) =>
          isActive ? "link" : "linkActive"
        }
      >
        <Grid container direction="column" justifyContent="space-between" className="cardSquare">
          <div className="square" /*style={{backgroundColor: theme.colors.secondary}}*/>
            <Grid container direction="column" justifyContent="flex-end" alignItems="center" className="intoSquare">
              <Grid item container direction="column" justifyContent="center" alignItems="center" className="iconSpace">
                {props.component}
              </Grid>
              <Grid item container direction="column" justifyContent="center" alignItems="center" className="titleSpace">
                <h1>{props.title}</h1>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </NavLink>
    </span>
  )
}