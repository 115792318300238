import React, { useState, useEffect } from 'react';
import "./MusicsPage.scss"
//import PictureSquare from '../../components/pictureSquare/PictureSquare';
import { Grid/*, useTheme*/, useMediaQuery, IconButton, Button } from "@mui/material";
//import _ from 'lodash'
import { useTranslation, Trans } from 'react-i18next';
import { getMusics, getMusicLists, updateMusicList, createMusic, deleteMusic } from '../../api/musics'
import { useParams, Link } from 'react-router-dom';
import { KeyboardDoubleArrowLeft, PlayCircle, Add, Delete, Edit } from '@mui/icons-material/';
import Loading from '../../components/loading/Loading';
import DialogAddAlbum from '../../components/dialog/DialogAddAlbum/DialogAddAlbum';
import DialogAddMusic from '../../components/dialog/DialogAddMusic/DialogAddMusic';
import { useSelector, useDispatch } from 'react-redux';
import DialogAreYouSure from '../../components/dialog/DialogAreYouSure/DialogAreYouSure';
import { setInfo } from '../../store/snackbar';

export default function MusicsPage() {
    const matches = useMediaQuery('(max-width:780px)');
    const { t } = useTranslation();
    const { id } = useParams();
    //const theme = useTheme();
    const [musics, setMusics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [musicList, setMusicList] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAddMusic, setOpenAddMusic] = useState(false);
    const [openAreYouSure, setOpenAreYouSure] = useState(false);
    const [selectedMusic, setSelectedMusic] = useState(null);
    const [hoverOn, setHoverOn] = useState(-1);
    const loggedInUser = useSelector(state => state.user.loggedInUser);
    const dispatch = useDispatch();

    useEffect(() => {
        let didCancel = false;
        window.scrollTo(0, 0);
        async function retrieveMusicLists() {
            try {
                let musicLists = await getMusicLists();
                let currentList = musicLists.find((el) => el.id.toString() === id.toString());
                if (currentList) {
                    !didCancel && setMusicList(currentList);
                    setIsLoading(false);
                } else {
                    throw new Error("404")
                }

            } catch (e) {
                dispatch(setInfo({ open: true, message: "musics.error.getList" }))
                setIsLoading(true);
            }
        }

        async function retrieveMusics() {
            try {
                !didCancel && setMusics(await getMusics({ id: id }))
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "musics.error.get" }))
                setIsLoading(true);
            }
        }

        if (id) {
            Promise.all([retrieveMusics(), retrieveMusicLists()])
        }

        return () => { didCancel = true; };
    }, [id])

    const onDelete = (el) => {
        setSelectedMusic(el);
        setOpenAreYouSure(true);
    }

    const musicComp = (el, i) => <>
        <Grid container alignItems="center" justifyContent="flex-start"
            className="musicContainer" >
            <Grid item sm={1} xs={2} className="numberContainer" onMouseOver={() => setHoverOn(i)} onMouseOut={() => setHoverOn(-1)}
                onClick={() => window.open(el.url, '_blank')}>
                {
                    hoverOn === i ?
                        <PlayCircle fontSize="large" />
                        :
                        <span className="number">{i}</span>
                }
            </Grid>
            <Grid item xs={8} sm={10} onMouseOver={() => setHoverOn(i)} onMouseOut={() => setHoverOn(-1)}
                onClick={() => window.open(el.url, '_blank')}>
                <p className="title">{el.name}</p>
                <p className="artist">{el.artist}</p>
            </Grid>
            <Grid item sm={1} xs={2}>
                {
                    musicList?.user?.id.toString() === loggedInUser.id.toString() &&
                    <Delete fontSize="large" onClick={() => onDelete(el)} />
                }</Grid>
        </Grid>
    </>

    const onUpdateMusicList = async (name, isPrivate, media) => {
        setIsLoading(true);
        try {
            let musicListTmp = { name };
            if (media !== musicList.media) {
                musicListTmp.media = media;
            }
            await updateMusicList(musicListTmp, musicList.id);
            musicListTmp.id = musicList.id;
            musicListTmp.media = media;
            musicListTmp.user = musicList.user;
            setMusicList(musicListTmp)
            setOpenEdit(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "musics.error.updateList" }))
        }
        setIsLoading(false);
    }

    const onAddMusic = async (name, artist, url) => {
        setIsLoading(true);
        try {
            let musicTmp = { name, artist, url };
            let newMusic = await createMusic(musicTmp, musicList.id);
            musicTmp.id = newMusic.id;
            setMusics([...musics, musicTmp])
            setOpenAddMusic(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "musics.error.add" }))
        }
        setIsLoading(false);
    }

    const toDelete = async () => {
        setIsLoading(true);
        try {
            await deleteMusic(musicList.id, selectedMusic.id);
            let musicsTmp = [...musics];
            let indexToDelete = musicsTmp.findIndex((el) => el.id === selectedMusic.id);
            if (indexToDelete >= 0) {
                musicsTmp.splice(indexToDelete, 1);
                setMusics(musicsTmp)
            }
            setOpenAreYouSure(false);
            setSelectedMusic(null);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "musics.error.delete" }))
        }
        setIsLoading(false);
    }

    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div className="musicsPage">

            {openAreYouSure && selectedMusic && selectedMusic.id && <DialogAreYouSure open={openAreYouSure} setOpen={() => setOpenAreYouSure(false)}
                title={t('musics.delete.title')} content={t("actions.areYouSure")} onClick={toDelete} />}

            {openEdit && <DialogAddAlbum
                open={openEdit} setOpen={setOpenEdit} title={t('medias.editAlbum.musics')}
                privateCheck={false} createAlbum={onUpdateMusicList}
                enabledPhoto={true}
                editInfo={{ name: musicList?.name, isPrivate: false, previewUrl: musicList?.media }} />
            }
            {openAddMusic && <DialogAddMusic
                open={openAddMusic} setOpen={setOpenAddMusic} title={t('musics.add.title')}
                onClick={onAddMusic} />
            }
            <Grid container alignItems="center">
                <Grid item sm={3} xs={12}>
                    <Grid container justifyContent="flex-start" alignItems="center" className="goBack">
                        <KeyboardDoubleArrowLeft className="icon" />
                        <Link to="/medias">{t("actions.goBackMedias")}</Link>
                    </Grid>
                </Grid>
                <Grid item sm={6} xs={12} className="title" container alignItems="center" justifyContent="center" spacing={1}>
                    <Grid item>
                        <h2 >
                            {musicList?.name}
                        </h2>
                    </Grid>
                    {
                        musicList?.user?.id.toString() === loggedInUser.id.toString() &&
                        <Grid item>
                            <IconButton onClick={() => setOpenEdit(true)}>
                                <Edit className="icon" />
                            </IconButton>
                        </Grid>
                    }


                </Grid>

            </Grid>
            <h2 className="sectionTitle">{t("musics.title")}</h2>
            <p className="text">{t('musics.desc')}</p>
            <Grid container justifyContent="space-between" alignItems={matches ? "center" : "flex-start"} direction={matches ? "column-reverse" : "row"}>
                <Grid item xs={12} sm={5} className="musicList">
                    {
                        musicList?.user?.id.toString() === loggedInUser.id.toString() &&
                        <Button
                            color="inherit"
                            variant="contained"
                            startIcon={<Add className="addIcon" />}
                            className="addButton"
                            fullWidth={matches}
                            onClick={() => setOpenAddMusic(true)}
                        >{t('musics.add.title')}</Button>
                    }
                    {
                        musics.map((el, i) => <React.Fragment key={`music_${i}`}>{musicComp(el, i + 1)}</React.Fragment>)
                    }
                </Grid>

                <Grid item xs={12} sm={7} container direction="column" alignItems="center">
                    <img src={musicList?.media} className="img" alt="music cover" />
                    <p><Trans i18nKey="musics.count"
                        values={{ count: musics.length }}
                        components={[]} /></p>
                </Grid>
            </Grid>
        </div >
    );
}