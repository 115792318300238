import React, { useState } from 'react';
import './PictureSquare.scss';
import { Grid, Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DeleteOutline } from '@mui/icons-material/';
import DialogAreYouSure from '../dialog/DialogAreYouSure/DialogAreYouSure';

export default function PictureSquare(props) {
  //const theme = useTheme();
  const { ableDelete, link, media, onDelete } = props;
  const [openAreYouSure, setOpenAreYouSure] = useState(false);
  let navigate = useNavigate();

  const goTo = () => {
    (!ableDelete || !onDelete.openAreYouSure) && navigate(link);
  }

  const toCloseAreYouSure = (e) => {
    e.stopPropagation();
    onDelete.setOpen(false);
    setOpenAreYouSure(false);
  }
  
  const toOpenAreYouSure = (e) => {
    e.stopPropagation();
    onDelete.setOpen(false);
    setOpenAreYouSure(true);
  }

  return (
    <Grid container direction="column" justifyContent="space-between" className="pictureSquareContainer">
      {ableDelete && openAreYouSure && <DialogAreYouSure open={openAreYouSure} setOpen={toCloseAreYouSure}
        title={onDelete.title} content={onDelete.content} onClick={onDelete.onClick} id={media} />}
      <div className="square" style={{ backgroundImage: `url(${media})` }}>
        <Grid container direction="column" justifyContent="flex-end" alignItems="flex-end" className="intoSquare">
          <Grid item container direction="column" justifyContent="center" alignItems="flex-end" className="iconSpace" onClick={link ? goTo : null} >
            <div className="grow" />
          </Grid>
          {
            ableDelete &&
            <Grid container justifyContent="flex-end">
              <Fab size="small" className="deleteButton" onClick={toOpenAreYouSure}>
                <DeleteOutline className="deleteIcon" />
              </Fab>
            </Grid>

          }
        </Grid>
      </div>
    </Grid>
  )
}