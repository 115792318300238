import axios from 'axios';
import { API_URL } from '../config';

export const getMessages = async () => {
  try {
    const response = await axios.get(`${API_URL}/messages`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data.messages;
  } catch (error) {
    throw error;
  }
}

export const createMessage = async (newMessage) => {
  try {
    const response = await axios.post(`${API_URL}/messages/`, newMessage, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const updateMessage = async (message, id) => {
  try {
    const response = await axios.put(`${API_URL}/messages/${id}`, message, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const deleteMessage = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/messages/${id}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}