import axios from 'axios';
import { API_URL } from '../config';

export const getSosContacts = async () => {
  try {
    const response = await axios.get(`${API_URL}/sos_contacts`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data.sos_contacts;
  } catch (error) {
    throw error;
  }
}

export const createSosContact = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/sos_contacts`, data, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateSosContact = async (data, id) => {
  try {
    const response = await axios.put(`${API_URL}/sos_contacts/${id}`, data, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteSosContact = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/sos_contacts/${id}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data;
  } catch (error) {
    throw error;
  }
}