import axios from 'axios';
import { API_URL } from '../config';

export const getMusicLists = async () => {
  try {
    const response = await axios.get(`${API_URL}/music_lists`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
      }
    })
    return response.data.music_lists;
  } catch (error) {
    throw error;
  }
}


export const getMusics = async ({id}) => {
  try {
      const response = await axios.get(`${API_URL}/music_lists/${id}/musics`, {
        headers: {
          'X-Password-Token': localStorage.getItem('token')
        }
      })
      return response.data.musics;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const updateMusicList = async (musicListData, id) => {
  try {
    const response = await axios.put(`${API_URL}/music_lists/${id}`, musicListData, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const deleteMusicList = async (list_id) => {
  try {
    const response = await axios.delete(`${API_URL}/music_lists/${list_id}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
        }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const deleteMusic = async (list_id, music_id) => {
  try {
    const response = await axios.delete(`${API_URL}/music_lists/${list_id}/${music_id}`, {
      headers: {
        'X-Password-Token': localStorage.getItem('token')
        }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}


export const createMusicList = async (newMusicList) => {
  try {
    const response = await axios.post(`${API_URL}/music_lists`, newMusicList, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const createMusic = async (newMusic, id) => {
  try {
    const response = await axios.post(`${API_URL}/music_lists/${id}`, newMusic, {
      headers: {
        'X-Password-Token': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}