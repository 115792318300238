import * as React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import "./Loading.css"
export default function Loading(props) {

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="space-between"
    >
      <div className="grow" />
      <Grid item xs={12} >
        <CircularProgress
          color="primary" />
      </Grid>
      <div className="grow" />
    </Grid>
  );
}
