import * as React from 'react';
import './UserCardSquare.scss';
import { Grid/*, useTheme*/, Avatar } from "@mui/material";
import { Edit } from '@mui/icons-material/';


export default function UserCardSquare(props) {
  //const theme = useTheme();
  const { user, disabledAvatar, edit, onClick } = props;

  return (
    <span className="userCardSquareContainer">
      <Grid container direction="column" justifyContent="space-between" className="cardSquare">
        <div className="square" /*style={{backgroundColor: theme.colors.secondary}}*/>
          <Grid container direction="column" justifyContent="flex-end" alignItems="center" className="intoSquare">
            <Grid item container direction="row" justifyContent="center" alignItems="center" className="titleSpace">
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={8}>
                {!disabledAvatar &&
                  <Avatar alt={`${user.firstname} ${user.lastname}`} src={user.avatar} className="avatar" />
                }
              </Grid>
              <Grid item xs={2}>
                {edit &&
                  <Edit onClick={onClick} />
                }
              </Grid>
            </Grid>
            <Grid item container direction="column" justifyContent="center" alignItems="center" className="contentSpace">
              <p className="name">{user.firstname} {user.lastname}</p>
              <p className="email">{user.email}</p>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </span>
  )
}