import React, { useState, useEffect } from 'react';
import "./MessagesPage.scss"
import Loading from '../../components/loading/Loading';
import { getMessages, createMessage, updateMessage, deleteMessage } from '../../api/messages';
import { getSenior } from '../../api/senior';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, useMediaQuery, Divider, Button, Fab } from "@mui/material";
import { Link } from 'react-router-dom';
import { KeyboardDoubleArrowLeft, Add, Done, DoneAll, Edit } from '@mui/icons-material/';
import DialogAddMessage from '../../components/dialog/DialogAddMessage/DialogAddMessage';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/de';
import * as localizedFormat from 'dayjs/plugin/localizedFormat'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { useDispatch } from 'react-redux';
import { setInfo } from '../../store/snackbar';

export default function MessagesPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [senior, setSenior] = useState(null);
    const [openNewMessage, setOpenNewMessage] = useState(false);
    const [openEditMessage, setOpenEditMessage] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const matches = useMediaQuery('(max-width:780px)');
    dayjs.locale(i18n.language);
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime)

    useEffect(() => {
        let didCancel = false;

        window.scrollTo(0, 0);

        async function retrieveMessages() {
            try {
                !didCancel && setMessages(await getMessages())
                setIsLoading(false);
            } catch (e) {
                dispatch(setInfo({ open: true, message: "messages.error.get" }))
                setIsLoading(true);
            }
        }


        async function retrieveSenior() {
            try {
                !didCancel && setSenior(await getSenior())
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        }


        Promise.all([retrieveMessages(), retrieveSenior()])
        return () => { didCancel = true; };
    }, [])

    const onCreateMessage = async (subject, body) => {
        setIsLoading(true);
        try {
            await createMessage({ subject, body });
            setMessages(await getMessages())
            setOpenNewMessage(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "messages.error.send" }))
        }
        setIsLoading(false);
    }

    const onUpdateMessage = async (subject, body) => {
        setIsLoading(true);
        try {
            await updateMessage({ subject, body }, selectedMessage.id);
            setMessages(await getMessages())
            setOpenEditMessage(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "messages.error.update" }))
        }
        setIsLoading(false);
    }

    const onDeleteMessage = async () => {
        setIsLoading(true);
        try {
            await deleteMessage(selectedMessage.id);
            setMessages(await getMessages())
            setOpenEditMessage(false);
        } catch (e) {
            dispatch(setInfo({ open: true, message: "messages.error.delete" }))
        }
        setIsLoading(false);
    }

    const onClickMessage = (message) => {
        setSelectedMessage(message);
        setOpenEditMessage(true)
    }


    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div className="messagePage">
            {openNewMessage && <DialogAddMessage open={openNewMessage} setOpen={setOpenNewMessage} title={t('messages.add.title')} onClick={onCreateMessage} />}

            {openEditMessage && selectedMessage && <DialogAddMessage open={openEditMessage} setOpen={setOpenEditMessage} title={t('messages.edit.title')} onClick={onUpdateMessage} editInfo={selectedMessage} onDelete={onDeleteMessage} />}

            {matches && <Fab size="small" className="addButtonMobile"
                onClick={() => setOpenNewMessage(true)}>
                <Add className="addIconMobile" />
            </Fab>}
            <Grid container alignItems="center">
                <Grid item sm={3} xs={12}>
                    <Grid container justifyContent="flex-start" alignItems="center" className="goBack">
                        <KeyboardDoubleArrowLeft className="icon" />
                        <Link to="/">{t("actions.goBack")}</Link>
                    </Grid>
                </Grid>

                <Grid item sm={6} xs={12} className="title">
                    <h1>{t("messages.title")}</h1>
                </Grid>

                {!matches && <Grid item xs={0} sm={3} style={{ textAlign: 'right' }}>
                    <Button
                        color="inherit"
                        variant="contained"
                        startIcon={<Add className="addIcon" />}
                        className="addButton"
                        onClick={() => setOpenNewMessage(true)}
                    >{t('messages.add.title')}</Button>
                </Grid>
                }

            </Grid>
            {
                messages.sort((a, b) => {
                    return new Date(b.updated_at) - new Date(a.updated_at)
                }).map((m) => <React.Fragment key={`message_${m.id}`}>
                    <Grid container justifyContent="space-between" className="messageItem" alignItems="baseline">
                        <Grid item xs={10} sm={10}>
                            <h2 className="subject">{m.subject}</h2>
                            <p className="body">{m.body}</p>
                        </Grid>
                        {
                            matches &&
                            <Grid item xs={2} style={{textAlign: "right"}}><Edit className="edit" onClick={() => onClickMessage(m)} /></Grid>
                        }
                        <Grid container item xs={12} sm={2} direction={matches ? "row" : "column"} justifyContent="flex-end" alignItems="flex-end">
                            {
                                !matches && <Grid item>
                                    <Edit className="edit" onClick={() => onClickMessage(m)} />
                                </Grid>
                            }
                            <Grid item container justifyContent="flex-end" alignItems="flex-end">
                                <Grid item>
                                    <p className="date">{dayjs(new Date(m.updated_at)).format("D MMM")}</p>
                                </Grid>
                                <Grid item>
                                    {m.is_read ? <DoneAll className="tick" /> : <Done className="tick" />}
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                    <Divider className="divider" />
                </React.Fragment>)
            }
            {
                messages.length === 0 && <p className="empty">
                    <Trans i18nKey="messages.empty"
                        values={{ firstname: senior?.firstname || t('messages.emptyDefault') }}
                        components={[<strong></strong>]} />
                </p>
            }

        </div>
    );
}